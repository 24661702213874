import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/school/Header';
import Footer from '../../components/school/Footer';
import Loading from '../../components/Loading';
import { capitalize, exportToXLS, formatPercentageOrFixed } from '../../../src/utils/utils';
import Api from '../../utils/api';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { calcFinanceValue } from '../../utils/utils-financial';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { dateFormatI18n } from '../../utils/formatters';
import { generateFinancialReportXLS } from '../../services/ReportService';
import { Box } from '@material-ui/core';
import moment from 'moment';

const Reports = () => {
  const { t, i18n } = useTranslation();

  // carregamentos
  const [students, setStudents] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [financials, setFinancials] = useState([]);
  const [employees, setEmployees] = useState([]);

  // loading
  const [process, setProcess] = useState(false);
  const [processStudent, setProcessStudent] = useState(false);
  const [processEmployee, setProcessEmployee] = useState(false);
  const [processContract, setProcessContract] = useState(false);
  const [processFinancial, setProcessFinancial] = useState(false);
  const [processAthletesClass, setProcessAthletesClass] = useState(false);

  // libraries
  const baseURL = Api();

  useEffect(() => {
    async function getSession() {
      const schoolSession = JSON.parse(await localStorage.getItem('school'));

      getReportsFinancials(schoolSession.id);
      getReportsStudents(schoolSession.id);
      getReportsContracts(schoolSession.id);
      getReportsEmployees(schoolSession.id);
    }

    getSession();
  }, []);

  const getReportsFinancials = async (school) => {
    setProcessFinancial(true);
    const responseFinancial = await axios.post(baseURL + 'get-reports-financial', {
      school_id: school,
    });

    setFinancials(
      responseFinancial.data.financials.map((fat) => ({
        ...fat,
        valueCalculated: Number(fat.valueReceived)
          ? +fat.valueReceived
          : calcFinanceValue(
            fat.value,
            fat.received === 'S',
            fat.datePayment,
            fat.dueDate,
            { type: fat.discount_type, value: fat.discount },
            { type: fat.late_interest_type, value: fat.late_interest },
            { type: fat.late_fee_type, value: fat.late_fee }
          ),
      }))
    );

    setProcessFinancial(false);
  };

  const getReportsStudents = async (school) => {
    setProcessStudent(true);
    const res = await axios.post(baseURL + 'get-reports-students', {
      school_id: school,
    });
    setStudents(res.data.students);
    setProcessStudent(false);
  };
  const getReportsContracts = async (school) => {
    setProcessContract(true);
    const res = await axios.post(baseURL + 'get-reports-contracts', {
      school_id: school,
    });
    setContracts(res.data.contracts);
    setProcessContract(false);
  };
  const getReportsEmployees = async (school) => {
    setProcessEmployee(true);
    const res = await axios.post(baseURL + 'get-reports-employees', {
      school_id: school,
    });
    setEmployees(res.data.employees);
    setProcessEmployee(false);
  };

  const formatInsurance = (insurance) => {
    const insurances = {
      NONE: null,
      PLAN_A: 1.99,
      PLAN_B: 2.99,
      PLAN_C: 3.99,
    };

    return insurances[insurance];
  };

  const handleExportToXLS = (type) => {
    let csvData = [];
    let fileName = '';

    setTimeout(() => {
      setProcessStudent(false);
      setProcessEmployee(false);
      setProcessContract(false);
      setProcessFinancial(false);
      setProcessAthletesClass(false);
    }, 3000);

    switch (type) {
      case 'student':
        {
          setProcessStudent(true);
          fileName = t('Students');

          const lateralidade = {
            A: 'Ambidextrous',
            C: 'LeftHanded',
            D: 'RightHanded',
          };

          for (const student of students) {
            csvData.push({
              [t('School')]: student.schName,
              [t('Name')]: student.name,
              [t('CPF')]: student.usuCPF,
              [t('Email')]: student.email,
              [t('CellPhone')]: student.usuPhone,
              [t('Birthday')]: dateFormatI18n(student.usuBirth, i18n.language),
              [t('Status')]: t(['StatusType.' + student.status, student.status]),
              [t('CEP')]: student.usuCEP,
              [t('Address')]: student.usuEndereco,
              [t('Neighborhood')]: student.usuBairro,
              [t('Type')]: t(['StudentModel.Type.' + student.athTipoStudent, student.athTipoStudent]),
              [t('CreatedAt')]: student.usuCreatedAt,
              [t('CreatedBy')]: student.userCreatedBy_name,
              [t('UpdatedAt')]: student.usuUpdatedAt,
              [t('UpdatedBy')]: student.userUpdatedBy_name,
              [t('BiologicalSex')]: student.usuGender,
              [t('ExpirationDateMedicalExamination')]: dateFormatI18n(
                student.expirationDateMedicalExamination,
                i18n.language
              ),

              [t('RG')]: student.athRG,

              [t('SchoolYear')]: student.schoolYear,
              [t('SkillLevel')]: student.skillLevel,
              [t('Sublevel')]: student.sublevel,

              [t('SportType')]: t(['Sports.Name.' + student.sportName, student.sportName || '']),
              [t('Position')]: t(['Sports.Position.' + student.posPosition, student.posPosition || '']),
              [t('HowKnowSchool')]: t(student.questionnaireName),
              [t('Laterality')]: t(lateralidade[student.athLateralidade]),
              [t('StudentCode')]: student.athCodigoAluno,

              [t('CEP')]: student.athCep,
              [t('State')]: student.staStateSigla,
              [t('City')]: student.citCity,
              [t('Address')]: student.athLogradouro,
              [t('Neighborhood')]: student.athBairro,

              [t('FinancialResponsibleName')]: student.athNomeRespFin,
              [t('FinancialResponsibleEmail')]: student.athEmailRespFin,
              [t('FinancialResponsibleRG')]: student.athRGRespFin,
              [t('FinancialResponsibleCPF')]: student.athCPFRespFin,
              [t('FinancialResponsibleCellPhone')]: student.athPhoneRespFin,
              [t('Kinship')]: t(student.athParentescoRespFin),

              [t('MotherName')]: student.athMaeNome,
              [t('MotherCPF')]: student.athMaeCPF,
              [t('MotherCellPhone')]: student.athMaeCelular,

              [t('FatherName')]: student.athPaiNome,
              [t('FatherCPF')]: student.athPaiCPF,
              [t('FatherCellPhone')]: student.athPaiCelular,
              [t('Benefit')]: student.benefitName,
              [t('Description')]: student.usuSobre,
            });
          }

          exportToXLS(fileName, csvData);
        }
        break;

      case 'employee':
        {
          setProcessEmployee(true);
          fileName = t('Employees');

          for (const employee of employees) {
            csvData.push({
              [t('Name')]: employee.name,
              [t('Role')]: employee.role_id === 6 ? 'Gestor' : 'Professor',
              [t('StartDate')]: dateFormatI18n(employee.usuDataInicioSchool, i18n.language),
              [t('CPF')]: employee.usuCPF,
              [t('Email')]: employee.email,
              [t('CellPhone')]: employee.usuPhone,
              [t('Birth')]: dateFormatI18n(employee.usuBirth, i18n.language),
              [t('Status')]: employee.status,
              [t('CEP')]: employee.usuCEP,
              [t('Address')]: employee.usuEndereco,
              [t('Neighborhood')]: employee.usuBairro,
              [t('City')]: employee.citCity,
              [t('State')]: employee.staStateSigla,
              [t('About')]: employee.usuSobre,
              [t('SportType')]: employee.sports.map((sport) => sport.label).join(),
              [t('Remuneration')]: employee.remuneration
            });
          }

          exportToXLS(fileName, csvData);
        }
        break;

      case 'contract':
        {
          setProcessContract(true);
          fileName = t('Contracts');
          for (const contract of contracts) {
            csvData.push({
              [t('Student')]: contract.nameStudent,
              [t('CPF')]: contract.usuCPF,
              [t('Email')]: contract.email,
              [t('Classes')]: contract.nameClass,
              [t('Responsible')]: contract.athNomeRespFin,
              [t('Service')]: contract.service,
              [t('Workload')]: contract.cargaHoraria,
              [t('BillingCycle')]: t(capitalize(contract.cyclo)),
              [t('Value')]: +contract.value,
              [t('ContractedInsurance')]: formatInsurance(contract.insurance),
              [t('DiscountType')]: t(capitalize(contract.discount_type || '')),
              [t('DiscountAmount')]: formatPercentageOrFixed(contract.discount_type, contract.discount),
              [t('LateInterestType')]: t(capitalize(contract.late_interest_type || '')),
              [t('LateInterestAmount')]: formatPercentageOrFixed(contract.late_interest_type, contract.late_interest),
              [t('LateFeeType')]: t(capitalize(contract.late_fee_type || '')),
              [t('LateFeeAmount')]: formatPercentageOrFixed(contract.late_fee_type, contract.late_fee),
              [t('Billing')]: contract.faturado,
              [t('Payment')]:
                contract.payment == 'CREDIT_CARD' ? 'Cartão' : contract.payment == 'MONEY' ? 'Dinheiro' : 'Boleto',
              [t('ContractStartDate')]: contract.contractDateStart
                ? dateFormatI18n(contract.contractDateStart, i18n.language)
                : '',
              [t('FirstDueDate')]: dateFormatI18n(contract.dateStart, i18n.language),
              [t('ContractEndDate')]: contract.dateEnd ? dateFormatI18n(contract.dateEnd, i18n.language) : '',
              [t('Status')]: contract.status,
              [t('ContractNumber')]: contract.number,
              [t('Observation')]: contract.observation,
              [t('CreatedAt')]: dateFormatI18n(contract.created_at, i18n.language),
              [t('CreatedBy')]: contract.user_id_name,
              [t('Observations')]: contract.observation,
            });
          }

          exportToXLS(fileName, csvData);
        }
        break;

      case 'financial':
        {
          setProcessFinancial(true);
          generateFinancialReportXLS(financials);
        }
        break;

      case 'athletesClass':
        {
          setProcessAthletesClass(true);
          fileName = t('Classes');
          const uniqueEntries = new Set();

          csvData = financials.filter(athletesClass => athletesClass.className).reduce((acc, athletesClass) => {
            const uniqueKey = `${athletesClass.className}-${athletesClass.student}-${athletesClass.dueDate}`;

            if (!uniqueEntries.has(uniqueKey)) {
              uniqueEntries.add(uniqueKey);

              acc.push({
                [t('Classes')]: athletesClass.className,
                [t('Student')]: athletesClass.student,
                [t('Birthday')]: dateFormatI18n(athletesClass.usuBirth, i18n.language),
                [t('DueDate')]: dateFormatI18n(athletesClass.dueDate, i18n.language),
                [t('Value')]: athletesClass.contratoValue,
                [t('PayDay')]: dateFormatI18n(athletesClass.datePayment, i18n.language),
                [t('AmountReceived')]: athletesClass.valueReceived,
                [t('FinancialResponsible')]: athletesClass.athNomeRespFin,
              });
            }
            return acc;
          }, []);

          exportToXLS(fileName, csvData);
        }

        break;

      case 'BirthdaysOfTheMonth':
        {
          setStudents(true);
          fileName = t('BirthdaysOfTheMonth');

          for (const BirthdaysOfTheMonth of students.filter((BirthdaysOfTheMonth) => {
            let birthdayMonth = BirthdaysOfTheMonth.usuBirth.split('-')[1];
            let currentMonth = moment().format('MM');
            return currentMonth === birthdayMonth;
          })) {
            csvData.push({
              [t('Name')]: BirthdaysOfTheMonth.name,
              [t('CellPhone')]: BirthdaysOfTheMonth.usuPhone,
              [t('Birthday')]: dateFormatI18n(BirthdaysOfTheMonth.usuBirth, i18n.language),
              [t('Status')]: BirthdaysOfTheMonth.status,
              [t('FinancialResponsibleName')]: BirthdaysOfTheMonth.athNomeRespFin,
              [t('FinancialResponsibleCellPhone')]: BirthdaysOfTheMonth.athPhoneRespFin,
              [t('FinancialResponsibleEmail')]: BirthdaysOfTheMonth.athEmailRespFin
            });
          }
          exportToXLS(fileName, csvData);
        }
        break;
    }
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>


            <main className='content_panel'>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('ReportsPage.PageTitle')}</h2>
                    <p className='card_subtitle'>{t('ReportsPage.PageSubtitle')}</p>
                  </div>
                  <div className='list_card'>
                    <div className='card_list_turma'>
                      <div className='content_card'>
                        <div className='card_details'>
                          <p className='card_details_label text_uppercase'>{t('ReportsPage.EmployeeReports')}</p>
                          <div className='btn_group_card'>
                            <button
                              style={{ background: 'white', border: 'none' }}
                              onClick={() => handleExportToXLS('employee')}
                              className='btn_link_card'
                              download='EmployeeReports'
                            >
                              {(process || processEmployee) && <Loading type='1' style={{ color: 'green' }} />}
                              {!process && !processEmployee && (
                                <svg
                                  width='22'
                                  height='22'
                                  viewBox='0 0 22 22'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path d='M16.0702 10.028C15.9567 9.78184 15.7068 9.625 15.4319 9.625H12.6261V0.6875C12.6261 0.308086 12.3118 0 11.9247 0H9.11885C8.73174 0 8.4174 0.308086 8.4174 0.6875V9.625H5.6116C5.33672 9.625 5.08683 9.78313 4.97329 10.028C4.85842 10.2743 4.90314 10.5617 5.0842 10.765L9.99435 16.265C10.1276 16.4149 10.3196 16.5 10.5218 16.5C10.7239 16.5 10.9159 16.4132 11.0492 16.265L15.9593 10.765C16.1417 10.5626 16.1838 10.2738 16.0702 10.028Z' />
                                  <path d='M18.2377 15.125V19.25H2.8058V15.125H0V20.625C0 21.3855 0.628674 22 1.4029 22H19.6406C20.4166 22 21.0435 21.3855 21.0435 20.625V15.125H18.2377Z' />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card_list_turma'>
                      <div className='content_card'>
                        <div className='card_details'>
                          <p className='card_details_label text_uppercase'>{t('ReportsPage.StudentReports')}</p>
                          <div className='btn_group_card'>
                            <button
                              style={{ background: 'white', border: 'none' }}
                              onClick={() => handleExportToXLS('student')}
                              className='btn_link_card'
                              download='StudentReports'
                            >
                              {processStudent && <Loading type='1' style={{ color: 'green' }} />}
                              {!processStudent && (
                                <svg
                                  width='22'
                                  height='22'
                                  viewBox='0 0 22 22'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path d='M16.0702 10.028C15.9567 9.78184 15.7068 9.625 15.4319 9.625H12.6261V0.6875C12.6261 0.308086 12.3118 0 11.9247 0H9.11885C8.73174 0 8.4174 0.308086 8.4174 0.6875V9.625H5.6116C5.33672 9.625 5.08683 9.78313 4.97329 10.028C4.85842 10.2743 4.90314 10.5617 5.0842 10.765L9.99435 16.265C10.1276 16.4149 10.3196 16.5 10.5218 16.5C10.7239 16.5 10.9159 16.4132 11.0492 16.265L15.9593 10.765C16.1417 10.5626 16.1838 10.2738 16.0702 10.028Z' />
                                  <path d='M18.2377 15.125V19.25H2.8058V15.125H0V20.625C0 21.3855 0.628674 22 1.4029 22H19.6406C20.4166 22 21.0435 21.3855 21.0435 20.625V15.125H18.2377Z' />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card_list_turma'>
                      <div className='content_card'>
                        <div className='card_details'>
                          <p className='card_details_label text_uppercase'>{t('ReportsPage.ContractReports')}</p>
                          <div className='btn_group_card'>
                            <button
                              style={{ background: 'white', border: 'none' }}
                              onClick={() => handleExportToXLS('contract')}
                              className='btn_link_card'
                              download='ContractReports'
                            >
                              {processContract && <Loading type='1' style={{ color: 'green' }} />}
                              {!processContract && (
                                <svg
                                  width='22'
                                  height='22'
                                  viewBox='0 0 22 22'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path d='M16.0702 10.028C15.9567 9.78184 15.7068 9.625 15.4319 9.625H12.6261V0.6875C12.6261 0.308086 12.3118 0 11.9247 0H9.11885C8.73174 0 8.4174 0.308086 8.4174 0.6875V9.625H5.6116C5.33672 9.625 5.08683 9.78313 4.97329 10.028C4.85842 10.2743 4.90314 10.5617 5.0842 10.765L9.99435 16.265C10.1276 16.4149 10.3196 16.5 10.5218 16.5C10.7239 16.5 10.9159 16.4132 11.0492 16.265L15.9593 10.765C16.1417 10.5626 16.1838 10.2738 16.0702 10.028Z' />
                                  <path d='M18.2377 15.125V19.25H2.8058V15.125H0V20.625C0 21.3855 0.628674 22 1.4029 22H19.6406C20.4166 22 21.0435 21.3855 21.0435 20.625V15.125H18.2377Z' />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className='card_list_turma'>
                      <div className='content_card'>
                        <div className='card_details'>
                          <p className='card_details_label text_uppercase'>{t('ReportsPage.FinancialReports')}</p>
                          <div className='btn_group_card'>
                            <button
                              style={{ background: 'white', border: 'none' }}
                              onClick={() => handleExportToXLS('financial')}
                              className='btn_link_card'
                              download='FinancialReports'
                            >
                              {processFinancial && <Loading type='1' style={{ color: 'green' }} />}
                              {!processFinancial && (
                                <svg
                                  width='22'
                                  height='22'
                                  viewBox='0 0 22 22'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path d='M16.0702 10.028C15.9567 9.78184 15.7068 9.625 15.4319 9.625H12.6261V0.6875C12.6261 0.308086 12.3118 0 11.9247 0H9.11885C8.73174 0 8.4174 0.308086 8.4174 0.6875V9.625H5.6116C5.33672 9.625 5.08683 9.78313 4.97329 10.028C4.85842 10.2743 4.90314 10.5617 5.0842 10.765L9.99435 16.265C10.1276 16.4149 10.3196 16.5 10.5218 16.5C10.7239 16.5 10.9159 16.4132 11.0492 16.265L15.9593 10.765C16.1417 10.5626 16.1838 10.2738 16.0702 10.028Z' />
                                  <path d='M18.2377 15.125V19.25H2.8058V15.125H0V20.625C0 21.3855 0.628674 22 1.4029 22H19.6406C20.4166 22 21.0435 21.3855 21.0435 20.625V15.125H18.2377Z' />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className='card_list_turma'>
                      <div className='content_card'>
                        <div className='card_details'>
                          <p className='card_details_label text_uppercase'>{t('ReportsPage.athletesClasses')}</p>
                          <div className='btn_group_card'>
                            <button
                              style={{ background: 'white', border: 'none' }}
                              onClick={() => handleExportToXLS('athletesClass')}
                              className='btn_link_card'
                              download='FinancialReports'
                            >
                              {(processFinancial || processAthletesClass) && (
                                <Loading type='1' style={{ color: 'green' }} />
                              )}
                              {!processFinancial && !processAthletesClass && (
                                <svg
                                  width='22'
                                  height='22'
                                  viewBox='0 0 22 22'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path d='M16.0702 10.028C15.9567 9.78184 15.7068 9.625 15.4319 9.625H12.6261V0.6875C12.6261 0.308086 12.3118 0 11.9247 0H9.11885C8.73174 0 8.4174 0.308086 8.4174 0.6875V9.625H5.6116C5.33672 9.625 5.08683 9.78313 4.97329 10.028C4.85842 10.2743 4.90314 10.5617 5.0842 10.765L9.99435 16.265C10.1276 16.4149 10.3196 16.5 10.5218 16.5C10.7239 16.5 10.9159 16.4132 11.0492 16.265L15.9593 10.765C16.1417 10.5626 16.1838 10.2738 16.0702 10.028Z' />
                                  <path d='M18.2377 15.125V19.25H2.8058V15.125H0V20.625C0 21.3855 0.628674 22 1.4029 22H19.6406C20.4166 22 21.0435 21.3855 21.0435 20.625V15.125H18.2377Z' />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className='card_list_turma'>
                      <div className='content_card'>
                        <div className='card_details'>
                          <p className='card_details_label text_uppercase'>{t('ReportsPage.AttendanceReport')}</p>
                          <div className='btn_group_card'>
                            <button style={{ background: 'white', border: 'none' }} className='btn_link_card'>
                              <Link to='/class-frequency-reports'>
                                <FaExternalLinkAlt size={20} />
                              </Link>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className='card_list_turma'>
                      <div className='content_card'>
                        <div className='card_details'>
                          <p className='card_details_label text_uppercase'>Relatório geral frequência</p>
                          <div className='btn_group_card'>
                            <button style={{ background: 'white', border: 'none' }} className='btn_link_card'>
                              <Link to='/general-frequency-school'>
                                <FaExternalLinkAlt size={20} />
                              </Link>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card_list_turma'>
                      <div className='content_card'>
                        <div className='card_details'>
                          <p className='card_details_label text_uppercase'>
                            {t('ReportsPage.DetailedFinancialReport')}
                          </p>
                          <div className='btn_group_card'>
                            <button style={{ background: 'white', border: 'none' }} className='btn_link_card'>
                              <Link to='/financial-school-reports'>
                                <FaExternalLinkAlt size={20} />
                              </Link>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card_list_turma'>
                      <div className='content_card'>
                        <div className='card_details'>
                          <p className='card_details_label text_uppercase'>{t('ReportsPage.CashFlowReport')}</p>
                          <div className='btn_group_card'>
                            <button style={{ background: 'white', border: 'none' }} className='btn_link_card'>
                              <Link to='/cash-flow-reports'>
                                <FaExternalLinkAlt size={20} />
                              </Link>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Turmas */}
                    <div className='card_list_turma'>
                      <div className='content_card'>
                        <div className='card_details'>
                          <p className='card_details_label text_uppercase'>Relatório Turmas</p>
                          <div className='btn_group_card'>
                            <button style={{ background: 'white', border: 'none' }} className='btn_link_card'>
                              <Link to='/reports-class-school'>
                                <FaExternalLinkAlt size={20} />
                              </Link>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='card_list_turma'>
                      <div className='content_card'>
                        <div className='card_details'>
                          <p className='card_details_label text_uppercase'>{t('ReportsPage.BirthdaysMonthReport')}</p>
                          <div className='btn_group_card'>
                            <button
                              style={{ background: 'white', border: 'none' }}
                              onClick={() => handleExportToXLS('BirthdaysOfTheMonth')}
                              className='btn_link_card'
                              download={t('ReportsPage.BirthdaysMonthReport')}
                            >
                              {processStudent && <Loading type='1' style={{ color: 'green' }} />}
                              {!processStudent && (
                                <svg
                                  width='22'
                                  height='22'
                                  viewBox='0 0 22 22'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path d='M16.0702 10.028C15.9567 9.78184 15.7068 9.625 15.4319 9.625H12.6261V0.6875C12.6261 0.308086 12.3118 0 11.9247 0H9.11885C8.73174 0 8.4174 0.308086 8.4174 0.6875V9.625H5.6116C5.33672 9.625 5.08683 9.78313 4.97329 10.028C4.85842 10.2743 4.90314 10.5617 5.0842 10.765L9.99435 16.265C10.1276 16.4149 10.3196 16.5 10.5218 16.5C10.7239 16.5 10.9159 16.4132 11.0492 16.265L15.9593 10.765C16.1417 10.5626 16.1838 10.2738 16.0702 10.028Z' />
                                  <path d='M18.2377 15.125V19.25H2.8058V15.125H0V20.625C0 21.3855 0.628674 22 1.4029 22H19.6406C20.4166 22 21.0435 21.3855 21.0435 20.625V15.125H18.2377Z' />
                                </svg>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Reports;
