import React, { useState, forwardRef, useContext, useRef, useCallback, useEffect, useMemo } from 'react';
import { handleOnChange } from '../../../../src/utils/utils';
import { ModalWithMethods } from '../../../components/modal/Modal';
import { UserContext } from '../../../Context/UserContext';
import { getAllActiveLessons, getAllActiveLessonsClub, getAllLessonsClub } from '../../../services/LessonPlanningService';
import { fetchAllCategories } from '../../../services/CategoriesService';
import ReactTooltip from 'react-tooltip'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { fetchLessonContentSportsBySport } from '../../../services/LessonContentSports';
import { fetchLessonDidacticStrategy } from '../../../services/LessonDidacticStrategy';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { Success } from '../../../components/svgs/Success';
import { getAllActiveLessons as getAllActiveLessonsAdmin } from '../../../services/AdminService'
import { checkSubscription } from '../../../services/MicrocyclePaymentsService';

const EditLessonPlanning = (
  { lessonPlanning, onSubmit, onlyView, ...props },
  ref
) => {
  const { school } = useContext(UserContext);
  const inputRef = useRef(null);
  const inputRefClub = useRef(null);
  const inputRefAdmin = useRef(null);
  const inputRefClubClass = useRef(null);

  const [filters, setFilters] = useState({
    name: '',
    lesson: '',
    lessonClub: '',
    lessonAdmin: '',
    classClub: '',
    source: 'P',
    status: 'A',
    category: 0,
    content: 0,
    strategy: 0
  });
  // dados
  const [planning, setPlanning] = useState({
    id: undefined,
    name: '',
    status: 'A',
    classNumber: '',
    objective: '',
    category_id: 0,
  });
  const [planningsClub, setPlanningsClub] = useState([])
  const [planningsClubFiltered, setPlanningsClubFiltered] = useState([]);
  const [planningsClubSelected, setPlanningsClubSelected] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([])
  const [categoriesFilterSelected, setCategoriesFilterSelected] = useState([])
  const [contentSports, setContentSports] = useState([]);
  const [contentsFilterSelected, setContentsFilterSelected] = useState([])
  const [didacticStrategy, setDidacticStrategy] = useState([]);
  const [strategiesFilterSelected, setStrategiesFilterSelected] = useState([])
  const [lessons, setLessons] = useState([]);
  const [lessonsFiltered, setLessonsFiltered] = useState([]);
  const [lessonsSelected, setLessonsSelected] = useState([]);
  const [lessonsClub, setLessonsClub] = useState([]);
  const [lessonsClubFiltered, setLessonsClubFiltered] = useState([]);
  const [lessonsClubSelected, setLessonsClubSelected] = useState([]);
  const [lessonsAdmin, setLessonsAdmin] = useState([]);
  const [lessonsAdminFiltered, setLessonsAdminFiltered] = useState([]);
  const [lessonsAdminSelected, setLessonsAdminSelected] = useState([]);
  const [showLessonsFiltered, setShowLessonsFiltered] = useState(false)
  const [showLessonsClubFiltered, setShowLessonsClubFiltered] = useState(false)
  const [showLessonsAdminFiltered, setShowLessonsAdminFiltered] = useState(false)
  const [showPlanningsClubFiltered, setShowPlanningsClubFiltered] = useState(false)
  const [signatureStatus, setSignatureStatus] = useState()

  const [success, setSuccess] = useState(false);
  const [session, setSession] = useState('information');

  const inputLessonRef = useOutsideClick(() => {
    setTimeout(() => setShowLessonsFiltered(false), 100)
  });

  const inputLessonClubRef = useOutsideClick(() => {
    setShowLessonsClubFiltered(false);
  });

  const inputLessonAdminRef = useOutsideClick(() => {
    setShowLessonsAdminFiltered(false);
  });

  const inputPlanningsClubRef = useOutsideClick(() => {
    setShowPlanningsClubFiltered(false);
  });

  const onChange = handleOnChange(setPlanning);

  const onChangeFilters = handleOnChange(setFilters);

  const checkStatusSubscription = async () => {
    try {
      const status = await checkSubscription(school.id)
      setSignatureStatus(status ? 1 : 0)
    } catch (error) {
      console.error(error)
    }
  }

  const getAllCategories = async () => {
    const response = await fetchAllCategories();
    setCategories(response?.categories);
  }

  const getLessonContentSportsBySport = async () => {
    const response = await fetchLessonContentSportsBySport();
    setContentSports(response?.contentSports);
  }

  const getLessonDidacticStrategy = async () => {
    const response = await fetchLessonDidacticStrategy();
    setDidacticStrategy(response.didacticStrategies);
  }

  const fetchAllLessonsPlanningClub = async () => {
    const { status, plannings, message } = await getAllLessonsClub(1, 0);
    if (status === 'SUCCESS') {
      setPlanningsClub(plannings);
      setPlanningsClubFiltered(plannings);
    } else {
      console.log(message);
    }
  };

  const addCategories = useCallback((e) => {
    const id = Number(e.target.value)
    const name = e.target.selectedOptions[0].label
    if (id && !categoriesSelected?.find((value) => value.id === id)) setCategoriesSelected((old) => [...old, { id, name }])
  }, [categoriesSelected])

  const removeCategories = useCallback((value) => {
    const categories = categoriesSelected.filter((category) => category.id !== value.id)
    if (categories.length === 0) setPlanning((old) => { return { ...old, category_id: 0 } });
    setCategoriesSelected(categories)
  }, [categoriesSelected])

  const addFilterCategories = useCallback((e) => {
    const id = Number(e.target.value)
    const name = e.target.selectedOptions[0].label
    if (id && !categoriesFilterSelected?.find((value) => value.id === id)) setCategoriesFilterSelected((old) => [...old, { id, name }])
  }, [categoriesFilterSelected])

  const removeFilterCategories = useCallback((value) => {
    const categories = categoriesFilterSelected.filter((category) => category.id !== value.id)
    if (categories.length === 0) setFilters((old) => { return { ...old, category: 0 } });
    setCategoriesFilterSelected(categories)
  }, [categoriesFilterSelected])

  const addFilterContentSports = useCallback((e) => {
    const id = Number(e.target.value)
    const name = e.target.selectedOptions[0].label
    if (id && !contentsFilterSelected?.find((value) => value.id === id)) setContentsFilterSelected((old) => [...old, { id, name }])
  }, [contentsFilterSelected])

  const removeFiltersContents = useCallback((value) => {
    const contentSports = contentsFilterSelected.filter((contentSport) => contentSport.id !== value.id)
    if (contentSports.length === 0) setFilters((old) => { return { ...old, content: 0 } });
    setContentsFilterSelected(contentSports)
  }, [contentsFilterSelected])

  const addFilterStrategies = useCallback((e) => {
    const id = Number(e.target.value)
    const name = e.target.selectedOptions[0].label
    if (id && !strategiesFilterSelected?.find((value) => value.id === id)) setStrategiesFilterSelected((old) => [...old, { id, name }])
  }, [strategiesFilterSelected])

  const removeFilterStrategies = useCallback((value) => {
    const didacticStrategies = strategiesFilterSelected.filter((didacticStrategy) => didacticStrategy.id !== value.id)
    if (didacticStrategies.length === 0) setFilters((old) => { return { ...old, strategy: 0 } });
    setStrategiesFilterSelected(didacticStrategies)
  }, [strategiesFilterSelected])

  const handleSelectLessons = (lesson) => {
    console.log(lesson)
    const find = lessonsSelected?.find((value) => value.id === lesson.id);
    !find && setLessonsSelected(old => [...old, lesson])
    setFilters({ lesson: '' })
  }

  const handleSelectLessonsClub = (lesson) => {
    const find = lessonsClubSelected?.find((value) => value.id === lesson.id);
    !find && setLessonsClubSelected(old => [...old, lesson])
    setFilters({ lessonClub: '' })
  }

  const handleSelectLessonsAdmin = (lesson) => {
    const find = lessonsAdminSelected?.find((value) => value.id === lesson.id);
    !find && setLessonsAdminSelected(old => [...old, lesson])
    setFilters({ lessonAdmin: '' })
  }

  const handleSelectClassClub = (planning) => {
    const find = planningsClubSelected?.find((value) => value.id === planning.id);
    !find && setPlanningsClubSelected(old => [...old, planning])
    setFilters({ classClub: '' })
  }

  const validateInformation = (e) => {
    e.preventDefault()
    const validate = {}
    if (!planning?.name) validate['name'] = 'Nome da aula é obrigatório';
    if (!planning?.objective) validate['objective'] = 'Objetivo é obrigatório';
    if (!planning?.classNumber) validate['classNumber'] = 'Número da aula é obrigatório';
    if (!planning?.status) validate['status'] = 'Status é obrigatório';
    if (categoriesSelected?.length === 0) validate['categories'] = 'Selecione pelo menos uma categoria';
    if (Object.keys(validate)?.length === 0) setSession('school-task')
  }

  const validateTask = (e) => {
    e.preventDefault()
    const validate = {}
    if (lessonsSelected?.length === 0) validate['lessons'] = 'Selecione pelo menos uma tarefa';
    if (Object.keys(validate)?.length === 0) {
      setSession('club-task')
    } else {
      ReactTooltip.show(inputRef.current);
    }
  }

  const validateClubTask = (e) => {
    e.preventDefault()
    const validate = {}
    if (lessonsClubSelected?.length === 0) validate['lessons'] = 'Selecione pelo menos uma tarefa';
    if (Object.keys(validate)?.length === 0) {
      setSession('club-class')
    } else {
      ReactTooltip.show(inputRefClub.current);
    }
  }

  const validateClubClass = (e) => {
    e.preventDefault()
    const validate = {}
    if (planningsClubSelected?.length === 0) validate['planning'] = 'Selecione pelo menos uma aula';
    if (Object.keys(validate)?.length === 0) {
      setSession('resume')
    } else {
      ReactTooltip.show(inputRefClubClass.current);
    }
  }

  const fetchAllLessons = async () => {
    const { status, lessons, message } = await getAllActiveLessons();
    if (status === 'SUCCESS') {
      setLessons(lessons);
      setLessonsFiltered(lessons);
    } else {
      console.log(message);
    }
  };

  const fetchAllLessonsClub = async () => {
    const { status, lessons, message } = await getAllActiveLessonsClub();
    if (status === 'SUCCESS') {
      setLessonsClub(lessons);
      setLessonsClubFiltered(lessons);
    } else {
      console.log(message);
    }
  };

  const fetchAllLessonsAdmin = async () => {
    const { status, lessons, message } = await getAllActiveLessonsAdmin();
    if (status === 'SUCCESS') {
      setLessonsAdmin(lessons);
      setLessonsAdminFiltered(lessons);
    } else {
      console.log(message);
    }
  };

  const filterLessons = useCallback(() => {
    let data = lessons
    if (categoriesFilterSelected?.length > 0) {
      const categoriesArray = categoriesFilterSelected?.map((category) => category?.id);
      data = data?.filter((lesson) => lesson?.lesson_categories?.some((category) => categoriesArray?.includes(category?.id)))
    }
    if (contentsFilterSelected?.length > 0) {
      const contentsArray = contentsFilterSelected?.map((content) => content?.id);
      data = data?.filter((lesson) => lesson?.lesson_content_sports?.some((content) => contentsArray?.includes(content?.id)))
    }
    if (strategiesFilterSelected?.length > 0) {
      const strategiesArray = strategiesFilterSelected?.map((strategy) => strategy?.id);
      data = data?.filter((lesson) => lesson?.lesson_didactic_strategies?.some((strategy) => strategiesArray?.includes(strategy?.id)))
    }
    data = data?.filter((lesson) => lesson?.concept?.toUpperCase()?.includes(filters.lesson?.toUpperCase() || ''))
    setLessonsFiltered(data)
  }, [lessons, filters?.lesson, categoriesFilterSelected, contentsFilterSelected, strategiesFilterSelected])

  const onShow = () => {
    ref.current.clearMessage();
    setSession(onlyView ? 'resume' : 'information');
    setSuccess(false);
    getAllCategories();
    getLessonContentSportsBySport();
    getLessonDidacticStrategy();
    fetchAllLessons();
    fetchAllLessonsClub();
    fetchAllLessonsPlanningClub();
    fetchAllLessonsAdmin();
    checkStatusSubscription();
    setPlanning(lessonPlanning);
    setCategoriesSelected(lessonPlanning?.categories || []);
    setLessonsSelected(lessonPlanning?.lessons || []);
    setLessonsClubSelected(lessonPlanning?.lessonsClub || []);
    setLessonsAdminSelected(lessonPlanning?.lessonsAdmin || []);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    ref.current.clearMessage();

    const request = {
      ...planning,
      categories: categoriesSelected?.map((category) => category.id),
      lessons: lessonsSelected?.map((lesson) => lesson.id),
      lessonsClub: lessonsClubSelected?.map((lesson) => lesson.id),
      lessonsAdmin: lessonsAdminSelected?.map((lesson) => lesson.id),
      clubClass: planningsClubSelected?.map((planning) => planning.id)
    };

    const { status, messageKey } = planning?.id !== null
      ? await onSubmit({ id: planning.id, ...request })
      : await onSubmit({ school_id: school.id, ...request });

    if (status === 'SUCCESS') {
      setSuccess(true)
    } else {
      showErrorMessage(messageKey);
    }
  }

  const showErrorMessage = (message) => {
    ref.current.showErrorMessage(message);
    ref.current.scrollTop();
  };

  const categoriesSelect = useMemo(() => categories?.map((category) => <option key={category.id} value={category.id}>{category.name}</option>), [categories]);
  const categoriesSelectedList = useCallback((view = 'edit') => {
    return (
      <div className='d-flex align-items-center'>
        {categoriesSelected?.sort((a, b) => a.name - b.name)?.map((category) => {
          return (
            <div key={category?.id} className='d-flex justify-content-center align-items-center mr-3'>
              {view === 'edit' && <button type='button' onClick={() => removeCategories(category)} className='badge-items-button'>X</button>}
              <span className={view !== 'pdf' ? 'badge-items' : ''}>{category?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [categoriesSelected])
  const categoriesFilterSelectedList = useCallback(() => {
    return (
      <div className='d-flex align-items-center flex-wrap'>
        {categoriesFilterSelected?.sort((a, b) => a.name - b.name)?.map((category) => {
          return (
            <div key={category?.id} className='d-flex justify-content-center align-items-center mr-3'>
              <button type='button' onClick={() => removeFilterCategories(category)} className='badge-items-button'>X</button>
              <span className={'badge-items'}>{category?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [categoriesFilterSelected])
  const contentSportsSelect = useMemo(() => contentSports?.map((contentSport) => <option key={contentSport.id} value={contentSport.id}>{contentSport.name}</option>), [contentSports])
  const contentsFilterSelectedList = useCallback(() => {
    return (
      <div className='d-flex align-items-center flex-wrap'>
        {contentsFilterSelected?.sort((a, b) => a - b)?.map((contentSport) => {
          return (
            <div key={contentSport?.id} className='d-flex justify-content-center align-items-center mr-3'>
              <button type='button' onClick={() => removeFiltersContents(contentSport)} className='badge-items-button'>X</button>
              <span className={'badge-items'}>{contentSport?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [contentsFilterSelected])
  const didacticStrategySelect = useMemo(() => didacticStrategy?.map((didacticStrategy) => <option key={didacticStrategy.id} value={didacticStrategy.id}>{didacticStrategy.name}</option>), [didacticStrategy])
  const strategiesFilterSelectList = useCallback(() => {
    return (
      <div className='d-flex align-items-center flex-wrap'>
        {strategiesFilterSelected?.sort((a, b) => a - b)?.map((didactic) => {
          return (
            <div key={didactic?.id} className='d-flex justify-content-center align-items-center mr-3'>
              <button type='button' onClick={() => removeFilterStrategies(didactic)} className='badge-items-button'>X</button>
              <span className='badge-items'>{didactic?.name}</span>
            </div>
          )
        })}
      </div>
    )
  }, [strategiesFilterSelected])
  const lessonsList = useMemo(() => {
    return lessonsFiltered?.map((lesson) => {
      return (
        <div className='autocomplete-input-list' onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          handleSelectLessons(lesson)
        }
        }
          key={lesson.id}>
          {lesson?.concept}
        </div>
      )
    })
  }, [lessonsFiltered])
  const lessonListSelected = useMemo(() => {
    return lessonsSelected?.map((lesson) => {
      return (
        <div className='d-flex flex-column justify-content-center border rounded shadow p-3 mb-2' key={lesson.id}>
          <div className='d-flex flex-row justify-content-between'>
            <div className='card_details_label' style={{ fontSize: '16px' }}>Tarefa escola:</div>
            <div className='pl-4 pr-4 pt-1 pb-1 rounded-pill bg-danger text-white' style={{ cursor: 'pointer', fontSize: '12px' }} onClick={() => setLessonsSelected(lessonsSelected?.filter((value) => value.id !== lesson.id))}>Remover</div>
          </div>
          <div className='mt-2 font-weight-bold'>Descrição</div>
          <div>{lesson?.description}</div>
          <div className='mt-2 font-weight-bold'>{lesson?.videoURL}</div>
        </div>
      )
    })
  }, [lessonsSelected])
  const lessonsClubList = useMemo(() => {
    return lessonsClubFiltered?.map((lesson) => {
      return (
        <div className='autocomplete-input-list' onClick={() => handleSelectLessonsClub(lesson)} key={lesson.id}>
          {lesson?.concept}
        </div>
      )
    })
  }, [lessonsClubFiltered])
  const lessonClubListSelected = useMemo(() => {
    return lessonsClubSelected?.map((lesson) => {
      return (
        <div className='d-flex flex-column justify-content-center border rounded shadow p-3 mb-2' key={lesson.id}>
          <div className='d-flex flex-row justify-content-between'>
            <div className='card_details_label' style={{ fontSize: '16px' }}>Tarefa clube:</div>
            <div className='pl-4 pr-4 pt-1 pb-1 rounded-pill bg-danger text-white' style={{ cursor: 'pointer', fontSize: '12px' }} onClick={() => setLessonsClubSelected(lessonsClubSelected?.filter((value) => value.id !== lesson.id))}>Remover</div>
          </div>
          <div className='mt-2 font-weight-bold'>Descrição</div>
          <div>{lesson?.description}</div>
          <div className='mt-2 font-weight-bold'>{lesson?.videoURL}</div>
        </div>
      )
    })
  }, [lessonsClubSelected])
  const lessonsAdminList = useMemo(() => {
    return lessonsAdminFiltered?.map((lesson) => {
      return (
        <div className='autocomplete-input-list' onClick={() => handleSelectLessonsAdmin(lesson)} key={lesson.id}>
          {lesson?.concept}
        </div>
      )
    })
  }, [lessonsAdminFiltered])
  const lessonAdminListSelected = useMemo(() => {
    return lessonsAdminSelected?.map((lesson) => {
      return (
        <div className='d-flex flex-column justify-content-center border rounded shadow p-3 mb-2' key={lesson.id}>
          <div className='d-flex flex-row justify-content-between'>
            <div className='card_details_label' style={{ fontSize: '16px' }}>Tarefa admin:</div>
            <div className='pl-4 pr-4 pt-1 pb-1 rounded-pill bg-danger text-white' style={{ cursor: 'pointer', fontSize: '12px' }} onClick={() => setLessonsAdminSelected(lessonsAdminSelected?.filter((value) => value.id !== lesson.id))}>Remover</div>
          </div>
          <div className='mt-2 font-weight-bold'>Descrição</div>
          <div>{lesson?.description}</div>
          <div className='mt-2 font-weight-bold'>{lesson?.videoURL}</div>
        </div>
      )
    })
  }, [lessonsAdminSelected])
  const classClubList = useMemo(() => {
    return planningsClubFiltered?.map((planning) => {
      return (
        <div className='autocomplete-input-list' onClick={() => handleSelectClassClub(planning)} key={planning.id}>
          {planning?.name}
        </div>
      )
    })
  }, [planningsClubFiltered])
  const classClubListSelected = useMemo(() => {
    return planningsClubSelected?.map((planning) => {
      return (
        <div className='d-flex flex-column justify-content-center border rounded shadow p-3 mb-2' key={planning.id}>
          <div className='d-flex flex-row justify-content-between'>
            <div style={{ fontSize: '16px' }}>
              <strong>Aula do Clube: </strong>
              <span className='secondary-color font-weight-bold'>{planning?.name}</span>
            </div>
            <div className='pl-4 pr-4 pt-1 pb-1 rounded-pill bg-danger text-white' style={{ cursor: 'pointer', fontSize: '12px' }} onClick={() => setPlanningsClubSelected(planningsClubSelected?.filter((value) => value.id !== planning.id))}>Remover</div>
          </div>
          <div className='mt-2 mb-2'><strong>Objetivo: </strong>{planning?.objective}</div>
          <div className='mb-2 secondary-color font-weight-bold'>Tarefa:</div>
          &#x2022; {planning?.lessons?.map((lesson, key, array) => `${lesson.concept} ${key !== (array?.length - 1) ? ', ' : ''}`)}
        </div>
      )
    })
  }, [planningsClubSelected])

  useEffect(() => {
    setPlanningsClubSelected(planning?.clubLesson?.map((p) => {
      const lessons = planningsClub?.find((value) => value.id === p.id)?.lessons
      return {
        ...p,
        lessons
      }
    }) || [])
  }, [planning?.clubLesson, planningsClub]);

  useEffect(() => {
    filterLessons();
  }, [filterLessons])

  return (
    <ModalWithMethods
      {...props}
      ref={ref}
      title={<div className='modal_title' style={{ padding: '0 30px' }}>{onlyView ? 'Visualizar Aula' : planning.id ? 'Editar Aula' : 'Criar Aula'}</div>}
      size={'lg'}
      onShow={onShow}
      modalContentStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 20 }}
    >
      {session === 'information' && (
        <>
          <div className='w-100'>
            <div className='planning-header-buttons'>
              <button className='btn-secondary-color mr-3'>Informação</button>
              <button className='btn-disabled mr-3' disabled>Tarefas Escola</button>
              <button className='btn-disabled mr-3' disabled>Tarefas Clube</button>
              <button className='btn-disabled mr-3' disabled>Tarefas Admin</button>
              <button className='btn-disabled mr-3' disabled>Aulas Clube</button>
              <button className='btn-disabled' disabled>Resumo</button>
            </div>
          </div>
          <div className='form_modal'>
            <div className='w-100' style={{ padding: '0 30px' }}>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form_group'>
                    <label className='card_details_label'>Nome da Aula</label>
                    <input
                      required
                      type='text'
                      className='form_control'
                      name='name'
                      value={planning.name}
                      onChange={onChange}
                      placeholder='Nome da Aula'
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form_group'>
                    <label className='card_details_label'>Objetivo</label>
                    <input
                      required
                      type='text'
                      className='form_control'
                      name='objective'
                      value={planning.objective}
                      onChange={onChange}
                      placeholder='Objetivo'
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form_group'>
                    <label className='card_details_label'>Número da Aula</label>
                    <input
                      required
                      type='number'
                      className='form_control'
                      name='classNumber'
                      value={planning.classNumber}
                      onChange={onChange}
                      placeholder='Número da Aula'
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form_group'>
                    <label htmlFor='status' className='card_details_label'>
                      Status
                    </label>
                    <select
                      required
                      name='status'
                      className='form_control select'
                      value={planning.status}
                      onChange={onChange}
                    >
                      <option value='' disabled hidden></option>
                      <option value='A'>Ativo</option>
                      <option value='I'>Inativo</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form_group'>
                    <label htmlFor='category_id' className='card_details_label'>
                      Categoria
                    </label>
                    <select
                      required
                      name='category_id'
                      className='form_control select'
                      value={planning.category_id}
                      onChange={(e) => {
                        onChange(e);
                        addCategories(e)
                      }}
                    >
                      <option value=''>Selecionar</option>
                      {categoriesSelect}
                    </select>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12 mb-2'>
                  <div className="d-flex align-items-center"><span className='bagde-title'>Categoria(s) selecionada(s)</span> {categoriesSelectedList()}</div>
                </div>
              </div>
              <div className='w-100 d-flex justify-content-center mt-4 mb-4'>
                <div className='main-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill'></div>
              </div>
              <div className='w-100 d-flex justify-content-center'>
                <button type='button' className='btn-main-color mr-3' onClick={() => ref.current.closeModal()}>
                  Voltar
                </button>
                <button className='btn-secondary-color' onClick={validateInformation}>
                  Avançar
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {session === 'school-task' && (
        <>
          <div className='w-100'>
            <div className='planning-header-buttons'>
              <button className='btn-disabled mr-3' disabled>Informação</button>
              <button className='btn-secondary-color mr-3'>Tarefas Escola</button>
              <button className='btn-disabled mr-3' disabled>Tarefas Clube</button>
              <button className='btn-disabled mr-3' disabled>Tarefas Admin</button>
              <button className='btn-disabled mr-3' disabled>Aulas Clube</button>
              <button className='btn-disabled' disabled>Resumo</button>
            </div>
          </div>
          <div className='form_modal'>
            <div className='w-100' style={{ padding: '0 30px' }}>
              <div className='row'>
                <div className='col-sm-10'>
                  <div className='form_group' ref={inputRef}>
                    <input
                      type='text'
                      className='form_control error'
                      name='lesson'
                      value={filters.lesson}
                      onChange={(e) => {
                        onChangeFilters(e);
                      }}
                      onClick={() => {
                        setShowLessonsFiltered(true)
                      }}
                      placeholder='Procurar tarefa'
                      style={{ position: 'relative' }}
                      data-for='lessonTooltip'
                      data-tip
                      ref={inputLessonRef}
                    />
                    <ReactTooltip id='lessonTooltip' type='warning'>
                      Escolha pelo menos uma tarefa
                    </ReactTooltip>
                    <div className='autocomplete-input' style={filters?.lesson || showLessonsFiltered ? { display: 'block' } : { display: 'none' }} onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}>
                      {lessonsList}
                    </div>
                  </div>
                </div>
                <div className='col-sm-2 d-flex justify-content-center align-items-center'>
                  Filtros {showFilters ?
                    <BsChevronDown className='ml-2' onClick={() => setShowFilters(false)} style={{ cursor: 'pointer' }} /> :
                    <BsChevronUp className='ml-2' onClick={() => setShowFilters(true)} style={{ cursor: 'pointer' }} />
                  }
                </div>
              </div>
              {showFilters && (
                <>
                  <div className='row'>
                    <div className='col-sm-4'>
                      <div className='form_group'>
                        <label htmlFor='category' className='card_details_label'>
                          Categoria
                        </label>
                        <select
                          required
                          name='category'
                          className='form_control select'
                          value={filters.category}
                          onChange={(e) => {
                            onChangeFilters(e);
                            addFilterCategories(e)
                          }}
                        >
                          <option value=''>Selecionar</option>
                          {categoriesSelect}
                        </select>
                      </div>
                      <div className='mt-2 mb-2'>
                        <div className='bagde-title mb-2'>Categoria(s) selecionada(s)</div>
                        <div>{categoriesFilterSelectedList()}</div>
                      </div>
                    </div>
                    <div className='col-sm-4'>
                      <div className='form_group'>
                        <label htmlFor='content_sport_id' className='card_details_label'>
                          Conteúdo
                        </label>
                        <select
                          required
                          name='content'
                          className='form_control select'
                          value={filters.content}
                          onChange={(e) => {
                            onChangeFilters(e);
                            addFilterContentSports(e);
                          }}
                        >
                          <option value=''>Selecionar</option>
                          {contentSportsSelect}
                        </select>
                      </div>
                      <div className='mt-2 mb-2'>
                        <div className='bagde-title mb-2'>Conteúdo(s) selecionado(s)</div>
                        <div>{contentsFilterSelectedList()}</div>
                      </div>
                    </div>
                    <div className='col-sm-4'>
                      <div className='form_group'>
                        <label htmlFor='didactic_strategy_id' className='card_details_label'>
                          Estratégia didática
                        </label>
                        <select
                          required
                          name='strategy'
                          className='form_control select'
                          value={filters.strategy}
                          onChange={(e) => {
                            onChangeFilters(e);
                            addFilterStrategies(e);
                          }}
                        >
                          <option value=''>Selecionar</option>
                          {didacticStrategySelect}
                        </select>
                      </div>
                      <div className='mt-2 mb-2'>
                        <div className='bagde-title mb-2'>Estratégia(s) selecionada(s)</div>
                        <div>{strategiesFilterSelectList()}</div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className='row'>
                <div className='col-sm-12 d-flex flex-column mt-3 mb-3' style={{ height: '250px', overflowY: 'auto' }}>
                  {lessonListSelected}
                </div>
              </div>
              <div className='w-100 d-flex justify-content-center mt-4 mb-4'>
                <div className='disable-pill mr-2'></div>
                <div className='main-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill'></div>
              </div>
              <div className='w-100 d-flex justify-content-center'>
                <button type='button' className='btn-main-color mr-3' onClick={() => setSession('information')}>
                  Voltar
                </button>
                <button className='btn-secondary-color' onClick={validateTask}>
                  Avançar
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {session === 'club-task' && (
        <>
          <div className='w-100'>
            <div className='planning-header-buttons'>
              <button className='btn-disabled mr-3' disabled>Informação</button>
              <button className='btn-disabled mr-3' disabled>Tarefas Escola</button>
              <button className='btn-secondary-color mr-3'>Tarefas Clube</button>
              <button className='btn-disabled mr-3' disabled>Tarefas Admin</button>
              <button className='btn-disabled mr-3' disabled>Aulas Clube</button>
              <button className='btn-disabled' disabled>Resumo</button>
            </div>
          </div>
          <div className='form_modal'>
            <div className='w-100' style={{ padding: '0 30px' }}>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form_group' ref={inputRefClub}>
                    <input
                      type='text'
                      className='form_control error'
                      name='lessonClub'
                      value={filters.lessonClub}
                      onChange={(e) => {
                        onChangeFilters(e);
                        setLessonsClubFiltered(lessonsClub?.filter((lesson) => lesson?.concept?.toUpperCase()?.includes(e?.target?.value?.toUpperCase() || '')))
                      }}
                      onClick={() => {
                        setShowLessonsClubFiltered(true)
                      }}
                      placeholder='Procurar tarefa'
                      style={{ position: 'relative' }}
                      data-for='lessonClubTooltip'
                      data-tip
                      ref={inputLessonClubRef}
                    />
                    <ReactTooltip id='lessonClubTooltip' type='warning'>
                      Escolha pelo menos uma tarefa
                    </ReactTooltip>
                    <div className='autocomplete-input' style={filters?.lessonClub || showLessonsClubFiltered ? { display: 'block' } : { display: 'none' }}>
                      {lessonsClubList}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12 d-flex flex-column mt-3 mb-3' style={{ height: '250px', overflowY: 'auto' }}>
                  {lessonClubListSelected}
                </div>
              </div>
              <div className='w-100 d-flex justify-content-center mt-4 mb-4'>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='main-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill'></div>
              </div>
              <div className='w-100 d-flex justify-content-center'>
                <button type='button' className='btn-main-color mr-3' onClick={() => setSession('school-task')}>
                  Voltar
                </button>
                <button className='btn-secondary-color' onClick={() => setSession(signatureStatus === 1 ? 'admin-task' : 'club-class')}>
                  Avançar
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {session === 'admin-task' && (
        <>
          <div className='w-100'>
            <div className='planning-header-buttons'>
              <button className='btn-disabled mr-3' disabled>Informação</button>
              <button className='btn-disabled mr-3' disabled>Tarefas Escola</button>
              <button className='btn-disabled mr-3' disabled>Tarefas Clube</button>
              <button className='btn-secondary-color mr-3' >Tarefas Admin</button>
              <button className='btn-disabled mr-3' disabled>Aulas Clube</button>
              <button className='btn-disabled' disabled>Resumo</button>
            </div>
          </div>
          <div className='form_modal'>
            <div className='w-100' style={{ padding: '0 30px' }}>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form_group' ref={inputRefAdmin}>
                    <input
                      type='text'
                      className='form_control error'
                      name='lessonAdmin'
                      value={filters.lessonAdmin}
                      onChange={(e) => {
                        onChangeFilters(e);
                        setLessonsAdminFiltered(lessonsAdmin?.filter((lesson) => lesson?.concept?.toUpperCase()?.includes(e?.target?.value?.toUpperCase() || '')))
                      }}
                      onClick={() => {
                        setShowLessonsAdminFiltered(true)
                      }}
                      placeholder='Procurar tarefa'
                      style={{ position: 'relative' }}
                      data-for='lessonAdminTooltip'
                      data-tip
                      ref={inputLessonAdminRef}
                    />
                    <ReactTooltip id='lessonAdminTooltip' type='warning'>
                      Escolha pelo menos uma tarefa
                    </ReactTooltip>
                    <div className='autocomplete-input' style={filters?.lessonAdmin || showLessonsAdminFiltered ? { display: 'block' } : { display: 'none' }}>
                      {lessonsAdminList}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12 d-flex flex-column mt-3 mb-3' style={{ height: '250px', overflowY: 'auto' }}>
                  {lessonAdminListSelected}
                </div>
              </div>
              <div className='w-100 d-flex justify-content-center mt-4 mb-4'>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='main-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill'></div>
              </div>
              <div className='w-100 d-flex justify-content-center'>
                <button type='button' className='btn-main-color mr-3' onClick={() => setSession(signatureStatus === 1 ? 'admin-task' : 'school-task')}>
                  Voltar
                </button>
                <button className='btn-secondary-color' onClick={() => setSession('club-class')}>
                  Avançar
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {session === 'club-class' && (
        <>
          <div className='w-100'>
            <div className='planning-header-buttons'>
              <button className='btn-disabled mr-3' disabled>Informação</button>
              <button className='btn-disabled mr-3' disabled>Tarefas Escola</button>
              <button className='btn-disabled mr-3' disabled>Tarefas Clube</button>
              <button className='btn-disabled mr-3' disabled>Tarefas Admin</button>
              <button className='btn-secondary-color mr-3'>Aulas Clube</button>
              <button className='btn-disabled' disabled>Resumo</button>
            </div>
          </div>
          <div className='form_modal'>
            <div className='w-100' style={{ padding: '0 30px' }}>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='form_group' ref={inputRefClubClass}>
                    <input
                      type='text'
                      className='form_control error'
                      name='classClub'
                      value={filters.classClub}
                      onChange={(e) => {
                        onChangeFilters(e);
                        setPlanningsClubFiltered(planningsClub?.filter((planning) => planning?.name?.toUpperCase()?.includes(e?.target?.value?.toUpperCase() || '')))
                      }}
                      onClick={() => {
                        setShowPlanningsClubFiltered(true)
                      }}
                      placeholder='Procurar aula'
                      style={{ position: 'relative' }}
                      data-for='classClubTooltip'
                      data-tip
                      ref={inputPlanningsClubRef}
                    />
                    <ReactTooltip id='classClubTooltip' type='warning'>
                      Escolha pelo menos uma aula
                    </ReactTooltip>
                    <div className='autocomplete-input' style={filters?.classClub || showPlanningsClubFiltered ? { display: 'block' } : { display: 'none' }}>
                      {classClubList}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12 d-flex flex-column mt-3 mb-3' style={{ height: '250px', overflowY: 'auto' }}>
                  {classClubListSelected}
                </div>
              </div>
              <div className='w-100 d-flex justify-content-center mt-4 mb-4'>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='main-pill mr-2'></div>
                <div className='disable-pill'></div>
              </div>
              <div className='w-100 d-flex justify-content-center'>
                <button type='button' className='btn-main-color mr-3' onClick={() => setSession('club-task')}>
                  Voltar
                </button>
                <button className='btn-secondary-color' onClick={() => setSession('resume')}>
                  Avançar
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {session === 'resume' && (
        <>
          <div className='w-100'>
            <div className='planning-header-buttons'>
              <button className='btn-disabled mr-3' disabled>Informação</button>
              <button className='btn-disabled mr-3' disabled>Tarefas Escola</button>
              <button className='btn-disabled mr-3' disabled>Tarefas Clube</button>
              <button className='btn-disabled mr-3' disabled>Tarefas Admin</button>
              <button className='btn-disabled mr-3' disabled>Aulas Clube</button>
              <button className='btn-secondary-color' >Resumo</button>
            </div>
          </div>
          <form onSubmit={handleSubmit} method='post' className='form_modal'>
            <div className='w-100' style={{ padding: '0 30px' }}>
              <div className='row'>
                <div className='col-sm-12 text-center'>
                  <h4>Aula:</h4>
                </div>
              </div>
              <div>
                <div className='row mt-3 border-bottom border-warning pb-3'>
                  <div className='col-sm-12'>
                    <div className='font-weight-bold'>Objetivo</div>
                    <div>{planning?.objective}</div>
                  </div>
                </div>
                <div className='row mt-3 border-bottom border-warning pb-3'>
                  <div className='col-sm-6 border-right border-warning'>
                    <div className='row'>
                      <div className='col-sm-12'>
                        <div className='font-weight-bold'>Nome da aula</div>
                        <div>{planning?.name}</div>
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div className='col-sm-12'>
                        <div className='font-weight-bold'>Categoria selecionadas</div>
                        <div className='resume'>
                          <ul>
                            {categoriesSelected?.map((category) => <li key={category.id}>{category.name}</li>)}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {planningsClubSelected && planningsClubSelected?.length > 0 && (
                      <div className='row mt-3'>
                        <div className='col-sm-12'>
                          <div className='font-weight-bold'>Aulas do clube</div>
                          <div className='resume'>
                            <ul>
                              {planningsClubSelected?.map((planning) => <li key={planning.id}>{planning.name}</li>)}
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-sm-6'>
                    <div className='row'>
                      <div className='col-sm-12'>
                        <div className='font-weight-bold'>Número da aula</div>
                        <div>{planning?.classNumber}</div>
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div className='col-sm-12'>
                        <div className='font-weight-bold'>Tarefa da escola</div>
                        <div className='resume'>
                          <ul>
                            {lessonsSelected?.map((lesson) => <li key={lesson.id}>{lesson.concept}</li>)}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {lessonsClubSelected && lessonsClubSelected?.length > 0 && (
                      <div className='row mt-3'>
                        <div className='col-sm-12'>
                          <div className='font-weight-bold'>Tarefa do clube</div>
                          <div className='resume'>
                            <ul>
                              {lessonsClubSelected?.map((lesson) => <li key={lesson.id}>{lesson.concept}</li>)}
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                    {lessonsAdminSelected && lessonsAdminSelected?.length > 0 && (
                      <div className='row mt-3'>
                        <div className='col-sm-12'>
                          <div className='font-weight-bold'>Tarefa do Admin</div>
                          <div className='resume'>
                            <ul>
                              {lessonsAdminSelected?.map((lesson) => <li key={lesson.id}>{lesson.concept}</li>)}
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='w-100 d-flex justify-content-center mt-4 mb-4'>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='disable-pill mr-2'></div>
                <div className='main-pill'></div>
              </div>
              {!onlyView && (
                <div className='w-100 d-flex justify-content-center mb-3'>
                  <button type='button' className='btn-main-color mr-3' onClick={() => setSession('club-class')}>
                    Voltar
                  </button>
                  <button className='btn-secondary-color' onClick={handleSubmit}>
                    Salvar Alterações
                  </button>
                </div>
              )}
            </div>
          </form>
          <div className="modal_success" style={success ? { display: 'flex', opacity: 1, alignSelf: 'center' } : undefined}>
            <div className='modal_content_success'>
              <div className="modal_header_success">
                <div className='modal_title_success'>{planning?.id === undefined ? 'Aula criada' : 'Alterações salvas'}</div>
                <div className='modal_close_success' onClick={() => ref.current.closeModal()}>
                  X
                </div>
              </div>
              <div className='modal_body_success'>
                <div className='modal_icon_success'>
                  <Success style={{ color: '#24c782' }} />
                </div>
                {planning?.id === undefined ? 'Aula criada com sucesso!' : 'Aula editada com sucesso!'}
              </div>
              <div className='modal_footer_success'>
                <button type='button' onClick={() => ref.current.closeModal()} className='btn-secondary-color w-100'>
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </ModalWithMethods >
  );
};

export default forwardRef(EditLessonPlanning);
