import { capitalize, exportToXLS, formatPercentageOrFixed } from '../utils/utils';
import api from './api';
import i18n from '../i18nextConf';
import { dateFormatI18n } from '../utils/formatters';
import { defaultApiObject, handleFail } from '../utils/utils-api';

export const getClassFrequencyReports = async (
  schoolId,
  { classId, hour, month, year, athleteName = null } = { classId: null, year: null, month: null }
) => {
  try {
    const { data } = await api.post('get-class-frequency-reports', {
      schoolId,
      classId,
      hour,
      monthYear: `${year}-${month}`,
      athleteName
    });
    return data;
  } catch (error) {
    return { success: false, messageKey: 'SystemMessage.Fail.InternalError' };
  }
};

export const getFinancialReports = async (schoolId, { filterDueDateIni, filterDueDateEnd, filterStatus, filterDateType, withClass }) => {
  try {
    const { data } = await api.post('get-financial-reports', {
      school_id: schoolId,
      filterDueDateIni,
      filterDueDateEnd,
      filterStatus,
      filterDateType,
      withClass,
    });

    return data;
  } catch (error) {
    return { success: false, messageKey: 'SystemMessage.Fail.InternalError' };
  }
};

export const generateFinancialReportXLS = (financial) => {
  const data = financial.map((finance) => ({
    [i18n.t('ContractNumber')]: finance.contratoNumero,
    [i18n.t('Customer')]:
      finance.type == i18n.t('Revenue') ? (finance.student !== null ? finance.student : finance.client) : '',
    [i18n.t('Provider')]:
      finance.type == i18n.t('Expense') ? (finance.student !== null ? finance.student : finance.client) : '',
    [i18n.t('Type')]: finance.type,
    [i18n.t('Account')]: finance.countDescription,
    [i18n.t('DueDate')]: dateFormatI18n(finance.dueDate, i18n.language),
    [i18n.t('Installment')]: finance.quota,
    [i18n.t('Received')]: finance.received,
    [i18n.t('Mode')]: finance.mode,
    [i18n.t('Observation')]: finance.observation,
    [i18n.t('Responsible')]: finance.respName,
    [i18n.t('ContractValue')]: +finance.value,
    [i18n.t('Value')]: finance.valueCalculated,
    [i18n.t('AmountReceived')]:
      typeof finance.valueReceived === 'number'
        ? finance.valueReceived.toFixed(2).replace(".", ",")
        : (finance.valueReceived || '').replace(".", ","),

    [i18n.t('DiscountType')]: i18n.t(capitalize(finance.discount_type || '')),
    [i18n.t('DiscountAmount')]: formatPercentageOrFixed(finance.discount_type, finance.discount),

    [i18n.t('LateInterestType')]: i18n.t(capitalize(finance.late_interest_type || '')),
    [i18n.t('LateInterestAmount')]: formatPercentageOrFixed(finance.late_interest_type, finance.late_interest),

    [i18n.t('LateFeeType')]: i18n.t(capitalize(finance.late_fee_type || '')),
    [i18n.t('LateFeeAmount')]: formatPercentageOrFixed(finance.late_fee_type, finance.late_fee),

    [i18n.t('Status')]: finance.payment_status,

    [i18n.t('PayDay')]: finance.datePayment ? dateFormatI18n(finance.datePayment, i18n.language) : '',

    [i18n.t('FinancialResponsible')]: finance.athNomeRespFin,
    [i18n.t('FinancialResponsibleCPF')]: finance.athCPFRespFin,
    [i18n.t('Payment')]: finance.payment,
    [i18n.t('PaymentId')]: finance.payment_id_asaas,
    [i18n.t('DowntownUser')]: finance.user_id_baixa_name,
    [i18n.t('Classes')]: finance.className,

    [i18n.t('CEP')]: finance.athCep,
    [i18n.t('State')]: finance.staStateSigla,
    [i18n.t('City')]: finance.citCity,
    [i18n.t('Address')]: finance.athLogradouro,
    [i18n.t('Neighborhood')]: finance.athBairro,

    [i18n.t('Observations')]: finance.observation,
  }));

  return exportToXLS(i18n.t('FinancialReportsPage.xlsFile'), data);
};

export const getCashFlowReport = async ({ schoolId, date }) => {
  try {
    const { data } = await api.post('/get-school-cash-flow', { school_id: schoolId, date });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};

export const getClubFinancesOnDay = async ({ clubId, date }) =>
  getFinancesOnDay({ type: 'CLUB', clubSchoolId: clubId, date });

export const getSchoolFinancesOnDay = async ({ schoolId, date }) =>
  getFinancesOnDay({ type: 'SCHOOL', clubSchoolId: schoolId, date });

const getFinancesOnDay = async ({ type, clubSchoolId, date }) => {
  try {
    const { data } = await api.post('/get-finances-on-day', { type, club_school_id: clubSchoolId, date });

    if (data.status === 'SUCCESS' || data.status === 'WARNING') {
      return data;
    } else {
      handleFail(data);
    }
  } catch (error) {
    return defaultApiObject(error.isFail, error, error.payload);
  }
};
