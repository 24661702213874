import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import Footer from '../../components/school/Footer';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import Badge from 'react-bootstrap/Badge';
import Utils, { correctImageOrientation, getUserPhoto } from '../../../src/utils/utils';
import { useTranslation } from 'react-i18next';
import { APIError } from '../../services/ExceptionClass/APIError';
import ModalInactivateStudent from '../../views/ModalInactivateStudent';
import moment from 'moment';

import {
  getStudentsSchool,
  saveStudentSchool,
  updateStudentSchool,
  getDocumentStudent,
  uploadDocumentStudent,
  destroyDocumentStudent,
  saveStudentAnamnesisForm,
  updateTrainingCenter,
  createTrainingCenter,
  getAllTrainingCenters,
  deleteTrainingCenter,
  getAllBenefits,
  updateBenefit,
  createBenefit,
  deleteBenefit,
} from '../../services/StudentService';
import {
  getCitysByStateSigla,
  verifyCpfSchool,
  verifyEmailSchoolUserId,
  verifyLogin,
  getQuestionnaires,
  getStates,
  getPositionsSchool,
  searchByZipCodeCountry,
  getBenefits,
} from '../../services/SharedService';
import { sendWhatsAppMessage } from '../../services/WhatsAppService';
import CpfCnpjMask from '../../components/inputs/CpfCnpjMask';
import PhoneMask from '../../components/inputs/PhoneMask';
import CEPMask from '../../components/inputs/CEPMask';
import { validateCPF, validatePhone, validateRG } from '../../utils/validators';
import { cellPhoneFormatI18n, dateFormatI18n } from '../../utils/formatters';
import ModalHealthForm from '../../views/ModalHealthForm';
import WhatsAppComponent from '../../components/whatsapp/WhatsAppComponent';
import ModalCTsManager from '../../views/ModalCTsManager';
import { useDispatch } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import AddDocumentsModal from '../../components/athlete/AddDocumentsModal';
import ModalBenefits from '../../views/ModalBenefits';

const Student = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const formRef = useRef(null);
  const studentFormRef = useRef(null);
  const modalInactivateStudentRef = useRef(null);
  const modalHealthFormRef = useRef(null);
  const modalScroll = useRef(null);
  const modalCTsManagerRef = useRef(null);
  const modalBenefitsRef = useRef(null);
  const whatsAppComponentRef = useRef();

  // libraries
  const { scrollTop } = Utils();
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();

  const [name, setName] = useState('');
  const [login, setLogin] = useState('');
  const [rg, setRG] = useState('');
  const [cpf, setCPF] = useState('');
  const [birth, setBirth] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [cep, setCEP] = useState('');
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [estado, setEstado] = useState('');
  const [nomeMae, setNomeMae] = useState('');
  const [nomePai, setNomePai] = useState('');
  const [cpfMae, setCPFMae] = useState('');
  const [cpfPai, setCPFPai] = useState('');
  const [phoneMae, setPhoneMae] = useState('');
  const [phonePai, setPhonePai] = useState('');
  const [posicao, setPosicao] = useState('');
  const [lateralidade, setLateralidade] = useState('');
  const [sobre, setSobre] = useState('');
  const [city_id, setCityID] = useState('');
  const [id, setID] = useState(null);
  const [athleteID, setAthleteID] = useState(null);
  const [school_id, setSchoolID] = useState('');
  const [companie_id, setCompanieID] = useState('');
  const [foto, setFoto] = useState('');
  const [newPhoto, setNewPhoto] = useState(null);
  const [nomeRespFin, setNomeRespFin] = useState('');
  const [rgRespFin, setRGRespFin] = useState('');
  const [cpfRespFin, setCPFRespFin] = useState('');
  const [phoneRespFin, setPhoneRespFin] = useState('');
  const [emailRespFin, setEmailRespFin] = useState('');
  const [status, setStatus] = useState('A');
  const [initialStatus, setInitialStatus] = useState(null);
  const [faturamento, setFaturamento] = useState('');
  const [tipo, setTipo] = useState('Regular');
  const [athleteDocuments, setAthleteDocuments] = useState([]);
  const [codigoAluno, setCodigoAluno] = useState('');
  const [parentesco, setParentesco] = useState('');
  const [questionnaire, setQuestionnaire] = useState('');
  const [sexo, setSexo] = useState('');
  const [expirationDateMedicalExamination, setExpirationDateMedicalExamination] = useState('');
  const [trainingTime, setTrainingTime] = useState([]);
  const [schoolYear, setSchoolYear] = useState('');
  const [skillLevel, setSkillLevel] = useState('1');
  const [sublevel, setSublevel] = useState('BEGINNER');
  const [CTLocation, setCTLocation] = useState('');
  const [mustReleaseCertificate, setMustReleaseCertificate] = useState(false);
  const [indication, setIndication] = useState('');
  const [benefit, setBenefit] = useState('');

  const [formErrors, setFormErrors] = useState({
    name: false,
    login: false,
    rg: false,
    cpf: false,
    birth: false,
    phone: false,
    email: false,
    cep: false,
    endereco: false,
    numero: false,
    complemento: false,
    bairro: false,
    estado: false,
    nomeMae: false,
    nomePai: false,
    cpfMae: false,
    cpfPai: false,
    phoneMae: false,
    phonePai: false,
    posicao: false,
    lateralidade: false,
    sobre: false,
    city_id: false,
    nomeRespFin: false,
    rgRespFin: false,
    cpfRespFin: false,
    phoneRespFin: false,
    emailRespFin: false,
    status: false,
    tipo: false,
    codigoAluno: false,
    parentesco: false,
    questionnaire: false,
    sexo: false,
    expirationDateMedicalExamination: false,
    trainingTime: false,
    schoolYear: false,
    skillLevel: false,
    sublevel: false,
    CTLocation: false,
    benefit: false,
    mustReleaseCertificate: false,
  });

  // filtros
  const [filterSchool, setFilterSchool] = useState('');
  const [filterName, setFilterName] = useState('');
  const [filterCity, setFilterCity] = useState('');
  const [filterNascimento, setFilterNascimento] = useState('');
  const [filterStatus, setFilterStatus] = useState('A');
  const [filterCodAluno, setFilterCodAluno] = useState('');
  const [filterSexo, setFilterSexo] = useState('');
  const [filterSport, setFilterSport] = useState('');
  const [filterStudentType, setFilterStudentType] = useState('');
  const [filterSublevel, setFilterSublevel] = useState('');
  const [filterSkillLevel, setFilterSkillLevel] = useState('');

  const [filterSchools, setFilterSchools] = useState([]);
  const [singleSchoolFetched, setSingleSchoolFetched] = useState(false);

  // carregamento
  const [students, setStudents] = useState([]);
  const [states, setStates] = useState([]);
  const [citys, setCitys] = useState([]);
  const [positions, setPositions] = useState([]);
  const [sports, setSports] = useState([]);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [studentsFilter, setStudentsFilter] = useState([]);
  const [dirUsuFotoPrincipal, setDirUsuFotoPrincipal] = useState('');
  const [display, setDisplay] = useState({});
  const [displayDocuments, setDisplayDocuments] = useState(false);
  const [sendDoc, setSendingDoc] = useState(false);
  const [mandatoryDocuments, setMandatoryDocuments] = useState([]);
  const [missingMandatoryDocuments, setMissingMandatoryDocuments] = useState([]);
  const [trainingCenters, setTrainingCenters] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [urlParams, setUrlParams] = useState(new URLSearchParams(window.location.search));

  // processing
  const [processing, setProcessing] = useState(true);
  const [sendCity, setSendingCity] = useState(false);
  const [sendCep, setSendingCep] = useState(false);
  const [sendSave, setSendSave] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [sendEmailResp, setSendEmailResp] = useState(false);
  const [sendCPF, setSendCPF] = useState(false);
  const [copySuccess, setCopySuccess] = useState('StudentPage.AddStudentCard.CopyLinkButton');
  const [copyShortSuccess, setCopyShortSuccess] = useState('StudentPage.AddStudentCard.CopyShortLinkButton');
  const [page, setPage] = useState(1);
  const [isPaging, setIsPaging] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [awaitPagination, setAwaitPagination] = useState(false);
  const [numberDataPerPaginate] = useState(12);
  const [isSendingAnamnesisForm, setIsSendingAnamnesisForm] = useState(false);
  const [isLoadingModalCTsManager, setIsLoadingModalCTsManager] = useState(false);

  // messages
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [countResearchedStudents, setCountResearchedStudents] = useState(0);
  const [countStudentsTotal, setCountStudentsTotal] = useState(0);
  const [countStudents, setCountStudents] = useState(0);
  const [countStudentsInactive, setCountStudentsInactive] = useState(0);
  const [countContracts, setCountContracts] = useState(0);

  //WhatsApp
  const [nameLink, setNameLink] = useState();
  const [phoneLink, setPhoneLink] = useState();

  const [tipoEsporte, setTipoEsporte] = useState('');
  const [location, setLocation] = useState(i18n.language);

  useEffect(() => {
    (async function getSession() {
      const companySession = JSON.parse(await localStorage.getItem('company'));
      let schoolSession = await localStorage.getItem('school');

      if (schoolSession !== 'null') {
        schoolSession = JSON.parse(schoolSession);

        setSchoolID(schoolSession.id);
        setFaturamento(schoolSession.schUtilizaFaturamento);
        setCompanieID(schoolSession.companie_id);
        getStundents(schoolSession.id, '');
        //TODO: Burcar os espores diretamenta da escola

        fetchPositions(schoolSession.id);
        fetchQuestionnaires(schoolSession.companie_id);
        fetchBenefits(schoolSession.companie_id);
      } else {
        setCompanieID(companySession.id);
        getStundents('', companySession.id);
      }
    })();

    const infiniteScrollElement = document.getElementById('infinite-scroll');
    infiniteScrollElement.addEventListener('scroll', triggerPagination);
    window.addEventListener('keydown', closeModalOnEscape);
    return () => {
      infiniteScrollElement.removeEventListener('scroll', triggerPagination);
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  useEffect(() => {
    // essa flag servirá para o scroll ser feito apenas para o primeiro input com erro de validação
    let hasScrolled = 0;
    const inputs = studentFormRef.current.querySelectorAll('input,select,textarea');

    const listener = function () {
      if (hasScrolled) return;
      hasScrolled = 1;
      // ajusta scroll após o scroll padrão do javascript
      setTimeout(() => {
        this.scrollIntoView({ behavior: 'smooth', block: 'center' });
        setTimeout(() => {
          hasScrolled = 0;
        }, 100);
      }, 100);
    };

    for (let input of inputs) {
      input.addEventListener('invalid', listener);
    }

    return () => {
      for (let input of inputs) {
        input.removeEventListener('invalid', listener);
      }
    };
  }, []);

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') {
      closeAdd();
      closeAddDocumento();
      whatsAppComponentRef.current.closeModal();
      modalInactivateStudentRef.current.closeModal();
    }
  };

  useEffect(() => {
    const infiniteScrolling = async () => {
      setAwaitPagination(true);

      const res = await getStudentsSchool({
        schoolId: school_id !== null ? school_id : null,
        companieId: companie_id,
        paginationOffset: page * numberDataPerPaginate,
        paginationLimit: numberDataPerPaginate,
        filters: getFilters(),
      });

      const newStudents = [...students, ...res.students];

      setStudents(newStudents);
      setStudentsFilter(newStudents);

      setPage(page + 1);
      setAwaitPagination(false);
      setIsPaging(false);
    };

    if (isPaging && students.length !== 0 && page < totalPages) {
      infiniteScrolling();
    } else {
      setIsPaging(false);
    }
  }, [isPaging]);

  useEffect(() => {
    fetchStates();
  }, [location]);

  const triggerPagination = () => {
    const infiniteScrollElement = document.getElementById('infinite-scroll');

    if (infiniteScrollElement) {
      const { scrollTop, clientHeight, scrollHeight } = infiniteScrollElement;

      if (scrollTop + clientHeight >= scrollHeight - clientHeight * 0.3) {
        setIsPaging(true);
      }
    }
  };

  const getStundents = async (school, companie) => {
    setProcessing(true);

    const res = await getStudentsSchool({
      schoolId: school !== null ? school : null,
      companieId: companie,
      paginationOffset: 0,
      paginationLimit: numberDataPerPaginate,
      filters: getFilters(),
    });

    setDirUsuFotoPrincipal(res.dir_usuFotoPrincipal);
    setStudents(res.students);
    setStudentsFilter(res.students);
    setCountResearchedStudents(res.countRows);
    setCountStudentsTotal(res.countStudentsTotal);
    setCountStudents(res.countStudents);
    setCountStudentsInactive(res.countStudentsInactive);
    setCountContracts(res.countContracts);
    setFilterSchools(res.schools);
    setMandatoryDocuments(res.mandatory_documents);
    setTrainingCenters(res.trainingCenters);
    console.log('aqui', {
      1: res.trainingCenters,
      2: res.benefits,
    });
    setBenefits(res.benefits);
    setSingleSchoolFetched(filterSchool !== '');

    setTotalPages(Math.ceil(res.countRows / numberDataPerPaginate));
    setPage(1);
    setProcessing(false);
  };

  const fetchStates = async () => {
    const res = await getStates(location);
    setStates(res.states);
    // setCitys([]);
  };

  const fetchPositions = async (id) => {
    const res = await getPositionsSchool({ school_id: id });
    setPositions(res.positions);
    setSports(res.sports);
  };

  const fetchQuestionnaires = async (companieId) => {
    const res = await getQuestionnaires(companieId);
    setQuestionnaires(res.questionnaire);
  };

  const getCitys = async (estado, localidade = '', locationZip = '') => {
    setCitys([]);
    setEstado(estado !== null ? estado : '');
    setSendingCity(true);

    const res = await getCitysByStateSigla(estado, locationZip !== '' ? locationZip : location);
    setSendingCity(false);

    for (let i = 0; i < res.cidades.length; i++) {
      let citCity = res.cidades[i].citCity;
      if (citCity == localidade) {
        setCityID(res.cidades[i].id);
      }
    }

    setCitys(res.cidades);
  };

  const buscaCEP = async () => {
    if (cep.length) {
      setSendingCep(true);
      const { success, streetName, neighborhood, states, state, cities, city } = await searchByZipCodeCountry(
        cep,
        location
      );
      setSendingCep(false);

      if (!success) {
        setEndereco('');
        setBairro('');
        setEstado('');
        setCityID('');
        setFormErrors({ ...formErrors, cep: true, endereco: false, bairro: false, estado: false, city_id: false });
      } else {
        setEndereco(streetName);
        setBairro(neighborhood);
        setStates(states);
        setEstado(state);

        setCitys(cities);
        setCityID(city.id);
        setFormErrors({ ...formErrors, cep: false, endereco: false, bairro: false, estado: false, city_id: false });
      }
    } else {
      setFormErrors({ ...formErrors, cep: false, endereco: false, bairro: false, estado: false, city_id: false });
    }
  };

  const getFilters = () => {
    return {
      filterSchool,
      filterName,
      filterStatus,
      filterSexo,
      filterCity,
      filterNascimento,
      filterCodAluno,
      filterSport,
      filterStudentType,
      filterSublevel,
      filterSkillLevel,
    };
  };

  const search = (e) => {
    if (e) e.preventDefault();
    getStundents(school_id, companie_id);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    const add = urlParams.get('add');
    if (add === 'true') {
      openAdd();
    }
  }, [urlParams]);
  const openAdd = () => {
    clearMessage();
    setName('');
    setLogin('');
    setRG('');
    setCPF('');
    setBirth('');
    setPhone('');
    setEmail('');
    setCEP('');
    setEndereco('');
    setNumero('');
    setComplemento('');
    setBairro('');
    setEstado('');
    setNomeMae('');
    setNomePai('');
    setCPFMae('');
    setCPFPai('');
    setPhoneMae('');
    setPhonePai('');
    setPosicao('');
    setLateralidade('');
    setExpirationDateMedicalExamination('');
    setSobre('');
    setCitys([]);
    setCityID('');
    setID(null);
    setFoto('');
    setNomeRespFin('');
    setRGRespFin('');
    setCPFRespFin('');
    setPhoneRespFin('');
    setEmailRespFin('');
    setStatus('A');
    setInitialStatus(null);
    setTipo('Regular');
    setCodigoAluno('');
    setParentesco('');
    setQuestionnaire('');
    setSexo('');
    setTipoEsporte('');
    setTrainingTime([]);
    setSchoolYear('');
    setSkillLevel('1');
    setSublevel('BEGINNER');
    setCTLocation('');
    setBenefit('');
    setMustReleaseCertificate(false);
    setIndication('');
    setAthleteID(null);
    setDisplay({
      display: 'flex',
      opacity: 1,
    });
  };

  const closeAdd = () => {
    setDisplay({});
    setAthleteID(null);
    setAthleteDocuments([]);
    URL.revokeObjectURL(foto);
  };

  const closeAddDocumento = (documents) => {
    setDisplayDocuments(false);

    if (!athleteID) {
      setAthleteDocuments(documents);
    }
  };

  const verifyCPF = async (cpf) => {
    if (i18n.language === 'pt-BR' && id == null && cpf.length == 11) {
      setSendCPF(true);
      const res = await verifyCpfSchool({ cpf: cpf, schoolId: school_id });
      setSendCPF(false);

      if (res.error === true) {
        setCPF('');
        setErrorMessage('StudentPage.SystemMessage.InvalidFields');
        setFormErrors({ ...formErrors, cpf: true });
      } else {
        clearMessage();
        setCPF(cpf);
        setFormErrors({ ...formErrors, cpf: false });
      }
    }
  };

  const verifyEmail = async (email, type) => {
    if (!/^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/.test(email)) {
      if (type === 'student') {
        setErrorMessage('StudentPage.SystemMessage.InvalidFields');
        setFormErrors({ ...formErrors, email: true });
      }
      if (type === 'res') {
        setErrorMessage('StudentPage.SystemMessage.InvalidFields');
        setFormErrors({ ...formErrors, emailRespFin: true });
      }
    } else {
      if (type === 'student') {
        // Comentando validação de e-mail cadastrado na mesma escola.
        // Agora e-mail pode ser cadastrado para mais de um aluno na mesma escola
        // setSendEmail(true);
        // const res = await verifyEmailSchoolUserId({ email: email, schoolId: school_id, id: id });
        // setSendEmail(false);
        // if (res.error === true) {
        //   setEmail('');
        //   setErrorMessage('StudentPage.SystemMessage.InvalidFields');
        //   setFormErrors({ ...formErrors, email: true });
        // } else {
        //   clearMessage();
        //   setFormErrors({ ...formErrors, email: false });
        // }
      } else if (type === 'res') {
        clearMessage();
        setFormErrors({ ...formErrors, emailRespFin: false });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearMessage();

    if (!validateCPF(cpf, i18n.language)) {
      setErrorMessage('StudentPage.SystemMessage.InvalidStudentCPF');
    } else if (phone) {
      if (!validatePhone(phone, i18n.language)) {
        setErrorMessage('StudentPage.SystemMessage.InvalidPhoneNumberStudent');
      }
    } else if (!validateRG(rg, i18n.language)) {
      setErrorMessage('StudentPage.SystemMessage.StudentRGExceedMaxSize');
    } else if (!validateCPF(cpfRespFin, i18n.language)) {
      setErrorMessage('StudentPage.SystemMessage.InvalidResponsibleCPF');
    } else if (!validateRG(rgRespFin, i18n.language)) {
      setErrorMessage('StudentPage.SystemMessage.ResponsibleRGExceedMaxSize');
    } else if (nomeRespFin.length > 90) {
      setErrorMessage('StudentPage.SystemMessage.InvalidInputMaxLenght', {
        input: t('FinancialResponsibleName'),
        count: 90,
      });
    } else if (endereco.length > 250) {
      setErrorMessage('StudentPage.SystemMessage.InvalidInputMaxLenght', { input: t('Address'), count: 250 });
    } else if (phoneRespFin) {
      if (!validatePhone(phoneRespFin, i18n.language)) {
        setErrorMessage('StudentPage.SystemMessage.InvalidPhoneNumber', { input: t('FinancialResponsibleName') });
      }
    }

    if (!error) {
      const body = {
        school_id,
        name,
        login,
        athRG: rg,
        usuCPF: cpf,
        usuBirth: birth,
        usuPhone: phone,
        email: email,
        athCep: cep,
        location_zip: location,
        athLogradouro: endereco,
        athNumero: numero,
        athComplemento: complemento,
        athBairro: bairro,
        athMaeNome: nomeMae,
        athPaiNome: nomePai,
        athMaeCPF: cpfMae,
        athPaiCPF: cpfPai,
        athMaeCelular: phoneMae,
        athPaiCelular: phonePai,
        position_id: posicao,
        sport_id: tipoEsporte,
        athLateralidade: lateralidade,
        expirationDateMedicalExamination: expirationDateMedicalExamination,
        usuSobre: sobre,
        city_id,
        athNomeRespFin: nomeRespFin,
        athRGRespFin: rgRespFin,
        athCPFRespFin: cpfRespFin,
        athEmailRespFin: emailRespFin,
        athParentescoRespFin: parentesco,
        athPhoneRespFin: phoneRespFin,
        usuStatus: status,
        athTipoStudent: tipo,
        athCodigoAluno: codigoAluno,
        questionnaire_id: questionnaire,
        usuGender: sexo,
        horarioTreino: trainingTime.join(';'),
        schoolYear,
        skillLevel,
        sublevel,
        novaFoto: newPhoto,
        training_center_id: CTLocation,
        benefit_id: benefit,
        allow_certificate_access: mustReleaseCertificate,
        pagination: { offset: 0, limit: numberDataPerPaginate },
        filters: getFilters(),
        athIndication: indication,
        benefit_id: benefit,
      };

      try {
        setSendSave(true);
        const { student, students, messageKey } =
          id === null
            ? await saveStudentSchool({
                user_id_create: JSON.parse(localStorage.getItem('user')).id,
                companie_id: companie_id,
                role_id: 2,
                usuStudent: 'S',
                password: body.athEmail,
                ...body,
              })
            : await updateStudentSchool({
                id: id,
                user_id_update: JSON.parse(localStorage.getItem('user')).id,
                ...body,
              });

        if (athleteDocuments.length) {
          await Promise.all(
            athleteDocuments.map((athleteDocument) =>
              uploadFile(athleteDocument.file, student.athlete.id, athleteDocument.idDocument)
            )
          );
        }
        setAthleteDocuments([]);
        setAthleteID(student.athlete.id);

        setSuccessMessage(messageKey);
        setID(student.user.id);
        setStudents(students);
        setStudentsFilter(students);
        setPage(1);

        if (initialStatus !== 'I' && status === 'I') modalInactivateStudentRef.current.showModal();
        setInitialStatus(status);

        if (newPhoto) {
          URL.revokeObjectURL(foto);
          setNewPhoto(null);
          setFoto(dirUsuFotoPrincipal + '/' + student.user.usuFotoPrincipal);
        }
      } catch (error) {
        if (error instanceof APIError) setErrorMessage(error.messageKey || error.message);
        else setErrorMessage('SystemMessage.Fail.ErrorRetry');
      } finally {
        setSendSave(false);
      }
    }
  };
  const handleFoto = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const newPhoto = await correctImageOrientation(file);

      URL.revokeObjectURL(foto);
      setFoto(URL.createObjectURL(newPhoto));
      setNewPhoto(newPhoto);
    }
  };

  const edit = (e, user, modal = 'EDIT') => {
    clearMessage();
    setLocation(user.location_zip ? user.location_zip : location);
    setID(user.idUser);
    setAthleteID(user.id);
    setName(user.name);
    setLogin(user.usuLogin);
    setRG(user.athRG ? user.athRG : '');
    setCPF(user.usuCPF ? user.usuCPF : '');
    setBirth(user.usuBirth ? user.usuBirth : '');
    setPhone(user.usuPhone ? user.usuPhone : '');
    setEmail(user.email ? user.email : '');
    setCEP(user.athCep ? user.athCep : '');
    setEndereco(user.athLogradouro ? user.athLogradouro : '');
    setNumero(user.athNumero ? user.athNumero : '');
    setComplemento(user.athComplemento ? user.athComplemento : '');
    setBairro(user.athBairro ? user.athBairro : '');
    setEstado(user.staStateSigla ? user.staStateSigla : '');
    setNomeMae(user.athMaeNome ? user.athMaeNome : '');
    setNomePai(user.athPaiNome ? user.athPaiNome : '');
    setCPFMae(user.athMaeCPF ? user.athMaeCPF : '');
    setCPFPai(user.athPaiCPF ? user.athPaiCPF : '');
    setPhoneMae(user.athMaeCelular ? user.athMaeCelular : '');
    setPhonePai(user.athPaiCelular ? user.athPaiCelular : '');
    setPosicao(user.position_id ? user.position_id : '');
    setLateralidade(user.athLateralidade ? user.athLateralidade : '');
    setExpirationDateMedicalExamination(user.expirationDateMedicalExamination || '');
    setSobre(user.usuSobre ? user.usuSobre : '');
    setCityID(user.city_id ? user.city_id : '');
    getCitys(user.staStateSigla, user.citCity ? user.citCity : '', user.location_zip);
    setNomeRespFin(user.athNomeRespFin ? user.athNomeRespFin : '');
    setRGRespFin(user.athRGRespFin ? user.athRGRespFin : '');
    setCPFRespFin(user.athCPFRespFin ? user.athCPFRespFin : '');
    setPhoneRespFin(user.athPhoneRespFin ? user.athPhoneRespFin : '');
    setEmailRespFin(user.athEmailRespFin ? user.athEmailRespFin : '');
    setStatus(user.usuStatus);
    setInitialStatus(user.usuStatus);
    setTipo(user.athTipoStudent ? user.athTipoStudent : 'Regular');
    setCodigoAluno(user.athCodigoAluno ? user.athCodigoAluno : '');
    setParentesco(user.athParentescoRespFin ? user.athParentescoRespFin : '');
    setQuestionnaire(user.questionnaire_id ? user.questionnaire_id : '');
    setSexo(user.usuGender ? user.usuGender : '');
    setTipoEsporte(user.sport_id ? user.sport_id : '');
    setTrainingTime(user.horarioTreino ? user.horarioTreino.split(';') : []);
    setSchoolYear(user.schoolYear);
    setSkillLevel(user.skillLevel);
    setSublevel(user.sublevel);
    setCTLocation(user.training_center_id ?? '');
    setBenefit(user.benefit_id ?? '');
    setMustReleaseCertificate(!!user.allow_certificate_access);
    setIndication(user.athIdication);

    if (user.usuFotoPrincipal !== null) setFoto(dirUsuFotoPrincipal + '/' + user.usuFotoPrincipal);
    else setFoto('');

    if (modal === 'EDIT') {
      setDisplay({
        display: 'flex',
        opacity: 1,
      });
    } else if (modal === '') {
      modalHealthFormRef.current.showModal();
      modalHealthFormRef.current.clearMessage();
    }
  };

  const getLinkToCreateStudent = () => {
    const queryParams = new URLSearchParams({ l: i18n.resolvedLanguage });
    return window.location.origin + '/student-create/' + companie_id + '/' + school_id + '?' + queryParams;
  };

  const getShortLinkToCreateStudent = () => {
    const queryParams = new URLSearchParams({ l: i18n.resolvedLanguage });
    return window.location.origin + '/student-create-short/' + companie_id + '/' + school_id + '?' + queryParams;
  };

  const copyToClipboard = async (type) => {
    try {
      const url = type === 'full' ? getLinkToCreateStudent() : getShortLinkToCreateStudent();

      if (navigator.clipboard != undefined) {
        await navigator.clipboard.writeText(url);
        if (type === 'full') {
          setCopySuccess('StudentPage.AddStudentCard.CopyLinkSuccessButton');
        } else {
          setCopyShortSuccess('StudentPage.AddStudentCard.CopyLinkSuccessButton');
        }

        setTimeout(() => {
          if (type === 'full') {
            setCopySuccess('StudentPage.AddStudentCard.CopyLinkButton');
          } else {
            setCopyShortSuccess('StudentPage.AddStudentCard.CopyShortLinkButton');
          }
        }, 2000);
      } else {
        window.open(url);
      }
    } catch (e) {
      console.error('erro ao copiar link para a área de transferência');
      console.error(e.message);
      console.log(window.isSecureContext);
    }
  };

  const editarDocumento = async (e, student) => {
    setAthleteID(student.id);
    setDisplayDocuments(true);
  };

  const uploadFile = async (file, athleteID, documentID) => {
    try {
      const res = await uploadDocumentStudent({
        athleteID: athleteID,
        documentID: documentID,
        athletedocument_id: null,
        file: file,
      });
      if ((res.original || {}).errors) {
        setErrorMessage(
          (Object.values(res.original.errors)[0] || [])[0] || 'Houve um erro inesperado ao tentar salvar o documento'
        );
        return;
      }

      setSuccessMessage('SystemMessage.File.Success.' + res.messageKey);
    } finally {
      setSendingDoc(false);
    }
  };

  const handleValidate = async (field) => {
    switch (field) {
      case 'name':
        if (name.length > 90) {
          setErrorMessage('StudentPage.SystemMessage.InvalidFields');
          setFormErrors({ ...formErrors, name: true });
        } else if (name.length <= 1) {
          setErrorMessage('StudentPage.SystemMessage.InvalidFields');
          setFormErrors({ ...formErrors, name: true });
        } else {
          clearMessage();
          setFormErrors({ ...formErrors, name: false });
        }
        break;
      case 'login':
        handlerValidateLogin();
        break;
      case 'rg':
        if (validateRG(rg, i18n.language)) {
          clearMessage();
          setFormErrors({ ...formErrors, rg: false });
        } else {
          setErrorMessage('StudentPage.SystemMessage.InvalidFields');
          setFormErrors({ ...formErrors, rg: true });
        }
        break;
      case 'cpf':
        if (validateCPF(cpf, i18n.language)) {
          if (await verifyCPF(cpf, i18n.language)) {
            clearMessage();
            setFormErrors({ ...formErrors, cpf: false });
          }
        } else {
          setErrorMessage('StudentPage.SystemMessage.InvalidFields');
          setFormErrors({ ...formErrors, cpf: true });
        }
        break;
      case 'cpfMae':
        if (cpfMae.length > 0 && !validateCPF(cpfMae, i18n.language)) {
          setErrorMessage('StudentPage.SystemMessage.InvalidFields');
          setFormErrors({ ...formErrors, cpfMae: true });
        } else {
          clearMessage();
          setFormErrors({ ...formErrors, cpfMae: false });
        }
        break;
      case 'birth':
        const selectedBirthDate = moment(birth);
        const currentDate = moment();
        if (birth.length > 0 && !handleValidateBirth(birth)) {
          setErrorMessage('StudentPage.SystemMessage.InvalidFields');
          setFormErrors({ ...formErrors, birth: true });
        } else {
          clearMessage();
          setFormErrors({ ...formErrors, birth: false });
        }
        if (selectedBirthDate.isAfter(currentDate)) {
          setErrorMessage('StudentPage.SystemMessage.BirthDateCannotBeFuture');
          setFormErrors({ ...formErrors, birth: true });
          return;
        } else if (selectedBirthDate.isValid()) {
          clearMessage();
          setFormErrors({ ...formErrors, birth: false });
        } else {
          setErrorMessage('StudentPage.SystemMessage.InvalidBirthDate');
          setFormErrors({ ...formErrors, birth: true });
        }
        break;
      case 'phone':
        if (phone) {
          if (validatePhone(phone, i18n.language)) {
            clearMessage();
            setFormErrors({ ...formErrors, phone: false });
          } else {
            setErrorMessage('StudentPage.SystemMessage.InvalidFields');
            setFormErrors({ ...formErrors, phone: true });
          }
        } else {
          clearMessage();
          setFormErrors({ ...formErrors, phone: false });
        }
        break;
      case 'schoolYear':
        if (schoolYear && schoolYear.length > 0) {
          if (!handleValidateBirth(schoolYear)) {
            setErrorMessage('StudentPage.SystemMessage.InvalidFields');
            setFormErrors({ ...formErrors, schoolYear: true });
          } else {
            clearMessage();
            setFormErrors({ ...formErrors, schoolYear: false });
          }
        } else {
          clearMessage();
          setFormErrors({ ...formErrors, schoolYear: false });
        }
        break;
      case 'email':
        if (email.length > 0) {
          await verifyEmail(email, 'student');
        } else {
          clearMessage();
          setFormErrors({ ...formErrors, email: false });
        }
        break;
      case 'cpfRespFin':
        if (cpfRespFin.length > 0 && !validateCPF(cpfRespFin, i18n.language)) {
          setErrorMessage('SystemMessage.SystemMessage.InvalidFields');
          setFormErrors({ ...formErrors, cpfRespFin: true });
        } else {
          clearMessage();
          setFormErrors({ ...formErrors, cpfRespFin: false });
        }
        break;
      case 'phoneRespFin':
        if (phoneRespFin.length > 0 && !validatePhone(phoneRespFin, i18n.language)) {
          setErrorMessage('StudentPage.SystemMessage.InvalidFields');
          setFormErrors({ ...formErrors, phoneRespFin: true });
        } else {
          clearMessage();
          setFormErrors({ ...formErrors, phoneRespFin: false });
        }
        break;
      default:
        break;
    }
  };

  const handleValidateBirth = (birth) => {
    const date = new Date(birth);
    if (date == 'Invalid Date') {
      return false;
    } else {
      return true;
    }
  };

  const handlerValidateLogin = async () => {
    if (login.length > 0) {
      const res = await verifyLogin({ login: login, id: id });
      if (res.error === true) {
        setErrorMessage('StudentPage.SystemMessage.InvalidFields');
        setFormErrors({ ...formErrors, login: true });
      } else {
        clearMessage();
        setFormErrors({ ...formErrors, login: false });
      }
    } else {
      setFormErrors({ ...formErrors, login: true });
      setErrorMessage('StudentPage.SystemMessage.InvalidFields');
    }
  };
  const setErrorMessage = (message, extra) => {
    if (extra) setMessage({ text: message, extra });
    else setMessage(message);
    setError(true);
    scrollTop(modalScroll);
  };

  const setSuccessMessage = (message, extra) => {
    if (extra) setMessage({ text: message, extra });
    else setMessage(message);
    setSuccess(true);
    scrollTop(modalScroll);
  };

  const clearMessage = () => {
    setSuccess(false);
    setError(false);
    setMessage('');
  };

  //WhatsApp
  const openSendWhatsApp = async () => {
    setNameLink('');
    setPhoneLink('');
    whatsAppComponentRef.current.showModal();
  };

  const sendMessageWhatsApp = async ({ name, phone }) => {
    const response = await sendWhatsAppMessage({
      numbersList: [i18n.resolvedLanguage === 'pt-BR' ? `55${phone}` : phone],
      message: t('WhatsAppComponent.Messages.StudentRegistrationLink', { name: name, link: getLinkToCreateStudent() }),
    });

    if (response.success) {
      return { success: true, message: t('WhatsAppComponent.SendSuccessMessage.LinkSentSuccessfully') };
    } else {
      return {
        success: false,
        message: (
          <div>
            <b>{t('WhatsAppComponent.SendFailMessage.Fail')}</b>
            <br />
            <b>{name}:</b> {t('WhatsAppComponent.SendFailMessage.IncorrectNumber')}
            <br />
          </div>
        ),
      };
    }
  };

  const toggleTrainingTime = (event) => {
    const value = event.target.value;
    if (event.target.checked) setTrainingTime((current) => [...current, value]);
    else setTrainingTime((current) => current.filter((c) => c !== value));
  };

  const onSubmitAnamnesisForm = async (anamnesisForm) => {
    setIsSendingAnamnesisForm(true);
    const { status, messageKey, message } = await saveStudentAnamnesisForm(athleteID, anamnesisForm);
    setIsSendingAnamnesisForm(false);

    modalHealthFormRef.current.scrollTop();
    if (status === 'SUCCESS') {
      modalHealthFormRef.current.showSuccessMessage(messageKey ? t(messageKey) : message);
    } else {
      modalHealthFormRef.current.showErrorMessage(messageKey ? t(messageKey) : message);
    }
  };

  const openCTsManager = () => {
    modalCTsManagerRef.current.showModal();
    fetchTrainingCenters();
  };

  const openBenefits = () => {
    modalBenefitsRef.current.showModal();
    fetchBenefits();
  };

  const fetchTrainingCenters = async () => {
    modalCTsManagerRef.current.clearMessage();
    setIsLoadingModalCTsManager(true);
    const { status, trainingCenters, messageKey } = await getAllTrainingCenters();
    setIsLoadingModalCTsManager(false);

    if (status === 'SUCCESS') {
      setTrainingCenters(trainingCenters);
    } else {
      setErrorMessage(messageKey);
    }
  };

  const fetchBenefits = async () => {
    modalBenefitsRef.current.clearMessage();
    setIsLoadingModalCTsManager(true);
    const { status, benefits, messageKey } = await getAllBenefits();
    setIsLoadingModalCTsManager(false);

    if (status === 'SUCCESS') {
      setBenefits(benefits);
    } else {
      setErrorMessage(messageKey);
    }
  };

  const onSubmitTrainingCenter = async (formData) => {
    modalCTsManagerRef.current.clearMessage();

    const {
      messageKey,
      status,
      trainingCenter: trainingCenterSaved,
      trainingCenters,
    } = formData.id ? await updateTrainingCenter(formData) : await createTrainingCenter(formData);

    if (status === 'SUCCESS') {
      setTrainingCenters(trainingCenters);

      return {
        messageKey,
        status,
        trainingCenter: trainingCenterSaved,
      };
    } else {
      return {
        messageKey,
        status,
        lesson: null,
      };
    }
  };

  const onSubmitBenefit = async (formData) => {
    modalBenefitsRef.current.clearMessage();

    const {
      messageKey,
      status,
      benefit: benefitSaved,
      benefits,
    } = formData.id ? await updateBenefit(formData) : await createBenefit(formData);

    if (status === 'SUCCESS') {
      setBenefits(benefits);

      return {
        messageKey,
        status,
        benefit: benefitSaved,
      };
    } else {
      return {
        messageKey,
        status,
        lesson: null,
      };
    }
  };

  const onDeleteTrainingCenter = async ({ id }) => {
    modalCTsManagerRef.current.clearMessage();

    const { messageKey, status, trainingCenters } = await deleteTrainingCenter({ id });

    if (status === 'SUCCESS') {
      setTrainingCenters(trainingCenters);

      return {
        messageKey,
        status,
      };
    } else {
      return {
        messageKey,
        status,
      };
    }
  };

  const onDeleteBenefit = async ({ id }) => {
    modalBenefitsRef.current.clearMessage();

    const { messageKey, status, benefits } = await deleteBenefit({ id });

    if (status === 'SUCCESS') {
      setBenefits(benefits);

      return {
        messageKey,
        status,
      };
    } else {
      return {
        messageKey,
        status,
      };
    }
  };

  const handlerEdit = (e, student) => {
    e.preventDefault();
    student.foto = getUserPhoto(dirUsuFotoPrincipal + '/', student.usuFotoPrincipal);
    student.athId = student.id;
    dispatch({ type: 'SET_USER', payload: student });
    history.push(`/student-edit/${student.user_id}`);
  };
  //TODO: Filtrar apenas esses sports no select do aluno de selacao de modalidades
  return (
    <main>
      <div id='infinite-scroll' className='container-fluid container_painel'>
        <div className='container-fluid'>
          <div className='row content_painel'>
            {/* {school_id !== '' ? <HeaderSchool /> : <HeaderClub />} */}

            <main className='content_panel'>
              <div className='sidebar'>
                {school_id !== '' && (
                  <div className='card' style={{ flexDirection: 'column' }}>
                    <div className='card_header'>
                      <h2 className='card_title'>{t('StudentPage.AddStudentCard.Title')}</h2>
                      <p className='card_subtitle'>{t('StudentPage.AddStudentCard.Subtitle')}</p>
                    </div>
                    <div className='card-actions'>
                      <div className='card-action-row'>
                        <button onClick={openAdd} className='btn_card mt-4 btn_open_modal' data-modal='#add_aluno'>
                          <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                          {t('StudentPage.AddStudentCard.Button')}
                        </button>

                        {/*   <Link to='/student-create-wizard' className='btn_card mt-4 btn_open_modal'>
                          <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                          {t('StudentPage.AddStudentCard.Button')}
                        </Link> */}
                        <button
                          onClick={openSendWhatsApp}
                          className='btn_card mt-4 btn_open_modal'
                          data-modal='#add_aluno'
                        >
                          <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                          {t('WhatsAppComponent.SendLinkButton')}
                        </button>
                      </div>
                      <div className='card-action-row'>
                        <button onClick={() => copyToClipboard('full')} className='btn_card mt-4 btn_open_modal'>
                          <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                          {t(copySuccess)}
                        </button>
                        <button onClick={() => copyToClipboard('short')} className='btn_card mt-4 btn_open_modal'>
                          <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                          {t(copyShortSuccess)}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('StudentPage.FilterStudentCard.Title')}</h2>
                    <p className='card_subtitle'>{t('StudentPage.FilterStudentCard.Subtitle')}</p>
                  </div>

                  <form action='' method='post' ref={formRef} className='form_card'>
                    {school_id === '' && (
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label'>
                          {t('School')}
                        </label>
                        <select
                          required
                          value={filterSchool}
                          className='form_control select'
                          onChange={(e) => setFilterSchool(e.target.value)}
                        >
                          <option value=''>{t('All', { context: 'female' })}</option>
                          {filterSchools.map((school) => (
                            <option key={`Student_FilterSchool_${school.id}`} value={school.id}>
                              {school.schFantasia || school.schName}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    <div className='form_group'>
                      <label htmlFor='name' className='card_details_label'>
                        {t('Name')}
                      </label>
                      <input
                        type='text'
                        className='form_control'
                        onChange={(e) => setFilterName(e.target.value.trim())}
                      />
                    </div>
                    <div className='form_group'>
                      <label htmlFor='title' className='card_details_label'>
                        {t('Status')}
                      </label>
                      <select
                        required
                        value={filterStatus}
                        className='form_control select'
                        onChange={(e) => setFilterStatus(e.target.value)}
                      >
                        <option value=''>{t('All')}</option>
                        <option value='A'>{t('StatusType.Active')}</option>
                        <option value='I'>{t('StatusType.Inactive')}</option>
                        <option value='P'>{t('StatusType.PreRegistered')}</option>
                      </select>
                    </div>
                    <div className='form_group'>
                      <label className='card_details_label flex-row'>{t('SkillLevel')}</label>
                      <select
                        className='form_control select'
                        value={filterSkillLevel}
                        onChange={(e) => setFilterSkillLevel(e.target.value)}
    
                      >
                        <option value=''></option>
                        <option value='1'>1</option>
                        <option value='2'>2</option>
                        <option value='3'>3</option>
                        <option value='4'>4</option>
                        <option value='5'>5</option>
                        <option value='6'>6</option>
                        <option value='7'>6 {t('Complete')}</option>
                      </select>
                    </div>
                    <div className='form_group'>
                      <label className='card_details_label flex-row'>{t('Sublevel')}</label>
                      <select
                        className='form_control select'
                        value={filterSublevel}
                        onChange={(e) => setFilterSublevel(e.target.value)}
                      >
                        <option value=''></option>
                        <option value='BEGINNER'>{t('Beginner')}</option>
                        <option value='INTERMEDIARY'>{t('Intermediary')}</option>
                        <option value='ADVANCED'>{t('Advanced')}</option>
                      </select>
                    </div>
                    <div className='form_group'>
                      <label htmlFor='how' className='card_details_label'>
                        {t('BiologicalSex')}
                      </label>
                      <select
                        className='form_control select'
                        value={filterSexo}
                        onChange={(e) => setFilterSexo(e.target.value)}
                      >
                        <option value=''></option>
                        <option value='F'>{t('Feminine')}</option>
                        <option value='M'>{t('Masculine')}</option>
                      </select>
                    </div>
                    <div className='form_group'>
                      <label htmlFor='name' className='card_details_label'>
                        {t('StudentCod')}
                      </label>
                      <input
                        type='text'
                        className='form_control'
                        onChange={(e) => setFilterCodAluno(e.target.value.trim())}
                      />
                    </div>

                    <div className='form_group'>
                      <label htmlFor='name' className='card_details_label'>
                        {t('City')}
                      </label>
                      <input type='text' className='form_control' onChange={(e) => setFilterCity(e.target.value)} />
                    </div>
                    <div className='form_group'>
                      <label htmlFor='name' className='card_details_label'>
                        {t('YearOfBirth')}
                      </label>
                      <InputMask
                        required
                        type='tel'
                        className='form_control'
                        mask='9999'
                        value={filterNascimento}
                        disableunderline='true'
                        onChange={(e) => setFilterNascimento(e.target.value)}
                      />
                    </div>
                    <div className='form_group'>
                      <label htmlFor='parentesco' className='card_details_label'>
                        {t('SportType')}
                      </label>
                      <select
                        className='form_control select'
                        value={filterSport}
                        onChange={(e) => setFilterSport(e.target.value)}
                      >
                        <option value=''></option>
                        {sports.map((sport) => (
                          <option key={'tipo-esporte' + sport.id} value={sport.id}>
                            {t('Sports.Name.' + sport.label)}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='form_group'>
                      <label htmlFor='title' className='card_details_label'>
                        {t('StudentType')}
                      </label>
                      <select
                        required
                        value={filterStudentType}
                        className='form_control select'
                        onChange={(e) => setFilterStudentType(e.target.value)}
                      >
                        <option value=''></option>
                        <option value='Social'>{t('StudentModel.Type.Social')}</option>
                        <option value='Regular'>{t('StudentModel.Type.Regular')}</option>
                        <option value='Seletiva'>{t('StudentModel.Type.Selective')}</option>
                        <option value='Alojado'>{t('StudentModel.Type.Housed')}</option>
                      </select>
                    </div>
                    {processing ? (
                      <button className='btn_card mx-auto' style={{ background: '#fdcc06', color: '#1c842c' }}>
                        <Loading type='1' />
                      </button>
                    ) : (
                      <button className='btn_card mx-auto' onClick={search}>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {t('Search')}
                      </button>
                    )}
                  </form>
                </div>
                <div className='card'>
                  {school_id === '' && (
                    <>
                      <div className='card_header mx-auto'>
                        <h2 className='card_title'>
                          {singleSchoolFetched ? t('RegisteredStudentsThisSchool') : t('RegisteredStudents')}
                        </h2>
                        {processing ? (
                          <p className='loading-card sidebar_data' style={{ height: '95px' }}>
                            <Loading type='1' />
                          </p>
                        ) : (
                          <div className='sidebar_data'>{countStudentsTotal}</div>
                        )}
                      </div>
                      <hr />
                    </>
                  )}
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>
                      {singleSchoolFetched ? t('ActiveStudentsThisSchool') : t('ActiveStudents')}
                    </h2>
                    {processing ? (
                      <p className='loading-card sidebar_data' style={{ height: '95px' }}>
                        <Loading type='1' />
                      </p>
                    ) : (
                      <div className='sidebar_data'>{countStudents}</div>
                    )}
                  </div>
                  <hr />
                  {school_id === '' && (
                    <>
                      <div className='card_header mx-auto'>
                        <h2 className='card_title'>
                          {singleSchoolFetched ? t('InactiveStudentsThisSchool') : t('InactiveStudents')}
                        </h2>
                        {processing ? (
                          <p className='loading-card sidebar_data' style={{ height: '95px' }}>
                            <Loading type='1' />
                          </p>
                        ) : (
                          <div className='sidebar_data'>{countStudentsInactive}</div>
                        )}
                      </div>
                      <hr />
                    </>
                  )}
                  <div className='card_header mx-auto'>
                    <h2 className='card_title'>{singleSchoolFetched ? t('ContractsThisSchool') : t('Contracts')}</h2>
                    {processing ? (
                      <p className='loading-card sidebar_data' style={{ height: '95px' }}>
                        <Loading type='1' />
                      </p>
                    ) : (
                      <div className='sidebar_data'>{countContracts}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>
                      {t('StudentPage.PageTitle')}
                      <Badge
                        pill
                        variant='success'
                        style={{
                          marginLeft: 3,
                          background: 'orange',
                          fontWeight: '500',
                        }}
                        className='badge-font-size-50'
                      >
                        {countResearchedStudents}
                      </Badge>
                    </h2>
                    <p className='card_subtitle'>{t('StudentPage.PageSubtitle')}</p>
                  </div>
                  <div className='list_card'>
                    {processing && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {!processing &&
                      studentsFilter.map((item, i) => {
                        return (
                          <div className='card_list' key={`employees_${i}`} style={{ maxWidth: '205px' }}>
                            <div className='img_list_user'>
                              <img src={getUserPhoto(dirUsuFotoPrincipal + '/', item.usuFotoPrincipal)} alt='' />
                            </div>
                            <div className='content_card'>
                              <div className='card_details'>
                                <p className='card_details_title' title={item.name}>
                                  {item.name.length > 20 ? item.name.substr(0, 20) + '...' : item.name}
                                </p>
                              </div>
                              <div
                                className='card_details'
                                style={{ justifyContent: 'left', alignItems: 'flex-start', marginBottom: 3 }}
                              >
                                <p className='card_details_label'>{t('StudentCod')}: </p>
                                <p className='card_details_text' title={item.athCodigoAluno}>
                                  {item.athCodigoAluno !== '' &&
                                  item.athCodigoAluno !== null &&
                                  item.athCodigoAluno.length > 14
                                    ? item.athCodigoAluno.substr(0, 14) + '...'
                                    : item.athCodigoAluno}
                                </p>
                              </div>
                              <div
                                className='card_details'
                                style={{ justifyContent: 'left', alignItems: 'flex-start', marginBottom: 3 }}
                              >
                                <p className='card_details_label'>{t('Status')}: </p>
                                <p className='card_details_text'>{t('StatusType.' + item.usuStatus)}</p>
                              </div>
                              <div
                                className='card_details'
                                style={{ justifyContent: 'left', alignItems: 'flex-start', marginBottom: 3 }}
                              >
                                <p className='card_details_label'>{t('Birth')}: </p>
                                <p className='card_details_text'>{dateFormatI18n(item.usuBirth, i18n.language)}</p>
                              </div>
                              <div
                                className='card_details'
                                style={{
                                  justifyContent: 'left',
                                  alignItems: 'flex-start',
                                  marginBottom: 3,
                                  minHeight: '36px',
                                }}
                              >
                                <p className='card_details_label'>{t('Position')}: </p>
                                <p className='card_details_text'>
                                  {item.posPosition ? t('Sports.Position.' + item.posPosition) : `(${t('Uninformed')})`}
                                </p>
                              </div>
                              {/* <div className='card_details' style={{ justifyContent: 'left', alignItems: 'flex-start' }}>
                                <p className='card_details_label'>{t('School')}: </p>
                                <p className='card_details_text'>{item.schName}</p>
                              </div> */}
                            </div>
                            <Link
                              style={{ marginBottom: 10, fontWeight: 500 }}
                              to={{
                                pathname: `/view-student/${item.user_id}`,
                              }}
                              className='btn_card'
                            >
                              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                              </svg>
                              {t('SeeStudent')}
                            </Link>
                            {/*  <Link
                              style={{ marginBottom: 10, fontWeight: 500 }}
                              to={{
                                pathname: `/detail-student/${item.user_id}`,
                              }}
                              className='btn_card'
                            >
                              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                              </svg>
                              {t('SeeStudent')}
                            </Link> */}
                            {school_id !== '' && (
                              <span
                                style={{
                                  backgroundColor: 'orange',
                                  fontWeight: 500,
                                  border: '1px solid orange',
                                  marginBottom: 10,
                                }}
                                onClick={(e) => edit(e, item)} //handlerEdit(e, item)
                                className='btn_card'
                              >
                                <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                  <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                                </svg>
                                {t('EditStudent')}
                              </span>
                            )}
                            <span
                              style={{
                                backgroundColor: '#aaa',
                                fontWeight: 500,
                                border: '1px solid #aaa',
                                marginBottom: 10,
                              }}
                              onClick={(e) => editarDocumento(e, item)}
                              className='btn_card'
                            >
                              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                              </svg>
                              {t('AttachDocs')}
                            </span>
                            <span
                              style={{
                                backgroundColor: '#aaa',
                                fontWeight: 500,
                                border: '1px solid #aaa',
                              }}
                              onClick={(e) => edit(e, item, '')}
                              className='btn_card'
                            >
                              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                              </svg>
                              {t('AnamnesisForm_abbreviated')}
                            </span>
                          </div>
                        );
                      })}
                    {awaitPagination && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className='modal' id='add_aluno' style={display}>
                <div className='modal_content' ref={modalScroll}>
                  <div className='modal_close' onClick={closeAdd}>
                    X
                  </div>
                  <h3 className='modal_title'>
                    {id === null && t('StudentPage.Modal.TitleAdd')}
                    {id !== null && t('StudentPage.Modal.TitleEdit')}
                  </h3>
                  {error && (
                    <MessageDangerMarginTop
                      title={t('Attention_exclamation') + ' '}
                      description={message.extra ? t(message.text, message.extra) : t(message)}
                    />
                  )}
                  {success && (
                    <MessageSuccessMarginTop
                      title={t('Success_exclamation') + ' '}
                      description={message.extra ? t(message.text, message.extra) : t(message)}
                    />
                  )}
                  <form action='' onSubmit={handleSubmit} method='post' className='form_modal' ref={studentFormRef}>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label flex-row required'>
                          {t('Name')}
                        </label>
                        <input
                          type='text'
                          required
                          className='form_control'
                          style={{ borderColor: formErrors.name && '#c00' }}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          onBlur={() => handleValidate('name')}
                        />
                      </div>
                      <div className='form_group'>
                        <label htmlFor='login' className='card_details_label flex-row required'>
                          {t('Login')}
                        </label>
                        <input
                          type='text'
                          required
                          className='form_control'
                          value={login}
                          onChange={(e) => setLogin(e.target.value)}
                          style={{ borderColor: formErrors.login && '#c00' }}
                          onBlur={() => handleValidate('login')}
                        />
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='rg' className='card_details_label'>
                            {t('RG')}
                          </label>
                          <input
                            type='text'
                            className='form_control'
                            value={rg}
                            onChange={(e) => setRG(e.target.value)}
                            style={{ borderColor: formErrors.rg && '#c00' }}
                            onBlur={() => handleValidate('rg')}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='cpf' className='card_details_label flex-row'>
                            {t('CPF')}
                            {sendCPF && <Loading type='1' style={{ marginLeft: '2%' }} />}
                          </label>
                          <CpfCnpjMask
                            value={cpf}
                            onChange={setCPF}
                            onBlur={() => handleValidate('cpf')}
                            mode='CPF'
                            style={{ borderColor: formErrors.cpf && '#c00' }}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='birth' className='card_details_label flex-row required'>
                            {t('BirthDate')}
                          </label>
                          <input
                            type='date'
                            required
                            className='form_control'
                            value={birth}
                            onChange={(e) => setBirth(e.target.value)}
                            style={{ borderColor: formErrors.birth && '#c00' }}
                            onBlur={() => handleValidate('birth')}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='phone' className='card_details_label'>
                            {t('CellPhone')}
                          </label>
                          <PhoneMask
                            value={phone}
                            onChange={setPhone}
                            style={{ borderColor: formErrors.phone && '#c00' }}
                            onBlur={() => handleValidate('phone')}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='title' className='card_details_label'>
                            {t('StudentType')}
                          </label>
                          <select
                            required
                            value={tipo}
                            className='form_control select'
                            onChange={(e) => setTipo(e.target.value)}
                            style={{ borderColor: formErrors.tipo && '#c00' }}
                          >
                            <option value='' disabled />
                            <option value='Social'>{t('StudentModel.Type.Social')}</option>
                            <option value='Regular'>{t('StudentModel.Type.Regular')}</option>
                            <option value='Seletiva'>{t('StudentModel.Type.Selective')}</option>
                            <option value='Alojado'>{t('StudentModel.Type.Housed')}</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='title' className='card_details_label'>
                            {t('Status')}
                          </label>
                          <select
                            required
                            value={status}
                            className='form_control select'
                            onChange={(e) => setStatus(e.target.value)}
                            style={{ borderColor: formErrors.status && '#c00' }}
                          >
                            <option value='' disabled></option>
                            <option value='A'>{t('StatusType.Active')}</option>
                            <option value='I'>{t('StatusType.Inactive')}</option>
                            <option value='P'>{t('StatusType.PreRegistered')}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {foto !== '' ? (
                      <div className='group_50' style={{ justifyContent: 'space-around' }}>
                        <img
                          style={{
                            borderRadius: 10,
                            marginBottom: 10,
                            width: '90%',
                          }}
                          src={foto}
                        />
                        <label
                          style={{
                            cursor: 'pointer',
                            padding: '12px 22px',
                            borderRadius: '50px',
                            border: '1px solid grey',
                            color: 'grey',
                            fontSize: '13px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'center',
                          }}
                        >
                          <input type='file' name='file[]' onChange={(e) => handleFoto(e)} />
                          {t('ChangePhoto')}
                        </label>
                      </div>
                    ) : (
                      <div className='group_50 upload_file'>
                        <label htmlFor='file'></label>
                        <input
                          type='file'
                          name='file[]'
                          id='file'
                          onChange={(e) => handleFoto(e)}
                          style={{
                            display: 'initial',
                            opacity: 0,
                            cursor: 'pointer',

                            position: 'relative',
                            top: '-52px',
                            width: '100%',
                            height: '100%',
                            borderColor: formErrors.file && '#c00',
                          }}
                        />
                      </div>
                    )}
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='parentesco' className='card_details_label'>
                            {t('SportType')}
                          </label>
                          <select
                            className='form_control select'
                            value={tipoEsporte}
                            onChange={(e) => setTipoEsporte(e.target.value)}
                            style={{ borderColor: formErrors.tipoEsporte && '#c00' }}
                          >
                            <option value='' disabled hidden></option>
                            {sports.map((sport) => (
                              <option key={'tipo-esporte' + sport.id} value={sport.id}>
                                {t('Sports.Name.' + sport.label)}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label flex-row'>{t('SchoolYear')}</label>
                          <input
                            type='text'
                            className='form_control'
                            value={schoolYear}
                            onChange={(e) => setSchoolYear(e.target.value)}
                            style={{ borderColor: formErrors.schoolYear && '#c00' }}
                            onBlur={() => handleValidate('schoolYear')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='parentesco' className='card_details_label'>
                          {t('Position')}
                        </label>
                        <select
                          className='form_control select'
                          value={posicao}
                          onChange={(e) => setPosicao(e.target.value)}
                          style={{ borderColor: formErrors.posicao && '#c00' }}
                        >
                          <option value='' disabled hidden></option>
                          {positions
                            .filter((position) => position.sport_id == tipoEsporte)
                            .sort((a, b) => a.posOrdem - b.posOrdem)
                            .map((position) => (
                              <option key={'posicao-' + position.id} value={position.id}>
                                {t('Sports.Position.' + position.posPosition)}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='form_group'>
                        <label
                          className='card_details_label'
                          style={{ flexDirection: 'row', justifyContent: 'space-between' }}
                        >
                          {t('CTLocation')}
                          <span
                            style={{
                              marginLeft: 10,
                              color: '#841202',
                              cursor: 'pointer',
                            }}
                            onClick={() => openCTsManager()}
                          >
                            {t('AddDeleteCTs')}
                          </span>
                        </label>
                        <select
                          className='form_control select'
                          value={CTLocation}
                          onChange={(e) => setCTLocation(e.target.value)}
                          style={{ borderColor: formErrors.CTLocation && '#c00' }}
                        >
                          <option value=''></option>
                          {trainingCenters.map((trainingCenter) => (
                            <option key={'training_center' + trainingCenter.id} value={trainingCenter.id}>
                              {trainingCenter.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label flex-row'>{t('SkillLevel')}</label>
                          <select
                            className='form_control select'
                            value={skillLevel}
                            onChange={(e) => setSkillLevel(e.target.value)}
                            style={{ borderColor: formErrors.skillLevel && '#c00' }}
                          >
                            <option value=''></option>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                            <option value='4'>4</option>
                            <option value='5'>5</option>
                            <option value='6'>6</option>
                            <option value='7'>6 {t('Complete')}</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label flex-row'>{t('Sublevel')}</label>
                          <select
                            className='form_control select'
                            value={sublevel}
                            onChange={(e) => setSublevel(e.target.value)}
                            style={{ borderColor: formErrors.sublevel && '#c00' }}
                          >
                            <option value=''></option>
                            <option value='BEGINNER'>{t('Beginner')}</option>
                            <option value='INTERMEDIARY'>{t('Intermediary')}</option>
                            <option value='ADVANCED'>{t('Advanced')}</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='email' className='card_details_label flex-row'>
                          {t('Email')}
                          {sendEmail && <Loading type='1' style={{ marginLeft: '2%' }} />}
                        </label>
                        <input
                          type='email'
                          className='form_control'
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          style={{ borderColor: formErrors.email && '#c00' }}
                          onBlur={() => handleValidate('email')}
                        />
                      </div>
                      <div className='form_group'>
                        <label htmlFor='how' className='card_details_label'>
                          {t('HowKnowSchool')}
                        </label>
                        <select
                          className='form_control select'
                          value={questionnaire}
                          onChange={(e) => setQuestionnaire(e.target.value)}
                          style={{ borderColor: formErrors.questionnaire && '#c00' }}
                        >
                          <option value=''></option>
                          {questionnaires.map((item) =>
                            item.status === 'A' ? (
                              <option value={item.id} key={item.id}>
                                {t(item.name)}
                              </option>
                            ) : (
                              <option value={item.id} key={item.id} disabled hidden>
                                {t(item.name)} ({t('DisabledOption')})
                              </option>
                            )
                          )}
                        </select>
                      </div>

                      <div className='form_group'>
                        <label
                          className='card_details_label'
                          style={{ flexDirection: 'row', justifyContent: 'space-between' }}
                        >
                          {t('Benefit')}
                          <span
                            style={{
                              marginLeft: 10,
                              color: '#841202',
                              cursor: 'pointer',
                            }}
                            onClick={() => openBenefits()}
                          >
                            Adicionar Beneficios
                          </span>
                        </label>
                        <select
                          className='form_control select'
                          value={benefit}
                          onChange={(e) => setBenefit(e.target.value)}
                          style={{ borderColor: formErrors.benefit && '#c00' }}
                        >
                          <option value=''></option>
                          {benefits.map((benefit) => (
                            <option key={'benefit' + benefit.id} value={benefit.id}>
                              {benefit.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='position' className='card_details_label'>
                            {t('Laterality')}
                          </label>
                          <select
                            onChange={(e) => setLateralidade(e.target.value)}
                            value={lateralidade}
                            className='form_control select'
                            style={{ borderColor: formErrors.lateralidade && '#c00' }}
                          >
                            <option value='' disabled></option>
                            <option value='D'>{t('RightHanded')}</option>
                            <option value='C'>{t('LeftHanded')}</option>
                            <option value='A'>{t('Ambidextrous')}</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>{t('TrainingTime')}</label>
                          <div style={{ height: '50px', marginTop: '5px' }}>
                            <label className='form_control checkbox'>
                              <input
                                type='checkbox'
                                value='MANHA'
                                checked={trainingTime.includes('MANHA')}
                                onChange={toggleTrainingTime}
                              />
                              {t('Morning')}
                            </label>
                            <label className='form_control checkbox'>
                              <input
                                type='checkbox'
                                value='TARDE'
                                checked={trainingTime.includes('TARDE')}
                                onChange={toggleTrainingTime}
                              />
                              {t('Evening')}
                            </label>
                            <label className='form_control checkbox'>
                              <input
                                type='checkbox'
                                value='NOITE'
                                checked={trainingTime.includes('NOITE')}
                                onChange={toggleTrainingTime}
                              />

                              {t('Night')}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='form_group'>
                        <label htmlFor='position' className='card_details_label'>
                          {t('ExpirationDateMedicalExamination')}
                        </label>
                        <input
                          className='form_control'
                          type='date'
                          value={expirationDateMedicalExamination}
                          onChange={(e) => setExpirationDateMedicalExamination(e.target.value)}
                          style={{ borderColor: formErrors.expirationDateMedicalExamination && '#c00' }}
                        />
                      </div>
                      <div className='form_group'>
                        <label htmlFor='descript' className='card_details_label'>
                          {t('StudentCode')}
                        </label>
                        <input
                          className='form_control'
                          value={codigoAluno}
                          onChange={(e) => setCodigoAluno(e.target.value)}
                          style={{ borderColor: formErrors.codigoAluno && '#c00' }}
                        />
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label className='card_details_label'>{t('ReleaseCertificate')}</label>
                          <select
                            required
                            className='form_control select'
                            value={mustReleaseCertificate === true ? '1' : '0'}
                            onChange={(e) => setMustReleaseCertificate(e.target.value == true)}
                            style={{ borderColor: formErrors.mustReleaseCertificate && '#c00' }}
                          >
                            <option value='' disabled hidden></option>
                            <option value='1'>{t('Yes')}</option>
                            <option value='0'>{t('Not')}</option>
                          </select>
                        </div>
                      </div>

                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='how' className='card_details_label'>
                            {t('BiologicalSex')}
                          </label>
                          <select
                            className='form_control select'
                            value={sexo}
                            onChange={(e) => setSexo(e.target.value)}
                            style={{ borderColor: formErrors.sexo && '#c00' }}
                          >
                            <option value='' disabled hidden></option>
                            <option value='F'>Feminino</option>
                            <option value='M'>Masculino</option>
                          </select>
                        </div>
                      </div>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label flex-row'>
                          {t('Indication')}
                        </label>
                        <input
                          className='form_control'
                          value={indication}
                          onChange={(e) => setIndication(e.target.value)}
                        />
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='tel' className='card_details_label'>
                            {t('Location')}
                          </label>
                          <select
                            className='form_control select'
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            style={{ borderColor: formErrors.location && '#c00' }}
                          >
                            <option value={'pt-BR'}>BRA</option>
                            <option value={'en-US'}>USA</option>
                            <option value={'en-US-Arabic'}>USA-ARABIC</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='name' className='card_details_label flex-row'>
                            {t('CEP')}
                            {sendCep && <Loading type='1' style={{ marginLeft: '2%' }} />}
                          </label>
                          <CEPMask
                            maskLocation={location === 'pt-BR' ? '#####-###' : '#####'}
                            value={cep}
                            onChange={setCEP}
                            onBlur={buscaCEP}
                            style={{ borderColor: formErrors.cep && '#c00' }}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='tel' className='card_details_label'>
                            {t('State')}
                          </label>
                          <select
                            className='form_control select'
                            value={estado}
                            onChange={(e) => getCitys(e.target.value, '')}
                            style={{ borderColor: formErrors.estado && '#c00' }}
                          >
                            <option value=''>-- {t('Select')} --</option>
                            {states.map((item) => (
                              <option value={item.staStateSigla} key={item.staState}>
                                {item.staState}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='name' className='card_details_label flex-row'>
                            {t('City')}
                            {sendCity && <Loading type='1' style={{ marginLeft: '2%' }} />}
                          </label>
                          <select
                            className='form_control select'
                            value={city_id}
                            onChange={(e) => setCityID(e.target.value)}
                            style={{ borderColor: formErrors.city_id && '#c00' }}
                          >
                            <option value=''>-- {t('Select')} --</option>
                            {citys.map((item) => (
                              <option value={item.id} key={item.citCity}>
                                {item.citCity}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label'>
                          {t('Address')}
                        </label>
                        <input
                          type='text'
                          value={endereco}
                          className='form_control'
                          onChange={(e) => {
                            if (e.target.value.length > 200) e.target.value = e.target.value.slice(0, 200);
                            setEndereco(e.target.value);
                          }}
                          maxLength={200}
                          style={{ borderColor: formErrors.endereco && '#c00' }}
                        />
                      </div>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label'>
                          {t('Number')}
                        </label>
                        <input
                          type='text'
                          value={numero}
                          className='form_control'
                          onChange={(e) => {
                            if (e.target.value.length > 200) e.target.value = e.target.value.slice(0, 200);
                            setNumero(e.target.value);
                          }}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          maxLength={200}
                          style={{ borderColor: formErrors.numero && '#c00' }}
                        />
                      </div>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label'>
                          {t('Complement')}
                        </label>
                        <input
                          type='text'
                          value={complemento}
                          className='form_control'
                          onChange={(e) => {
                            if (e.target.value.length > 200) e.target.value = e.target.value.slice(0, 200);
                            setComplemento(e.target.value);
                          }}
                          maxLength={200}
                          style={{ borderColor: formErrors.complemento && '#c00' }}
                        />
                      </div>
                      <div className='form_group'>
                        <label htmlFor='tel' className='card_details_label'>
                          {t('Neighborhood')}
                        </label>
                        <input
                          type='text'
                          value={bairro}
                          className='form_control'
                          onChange={(e) => setBairro(e.target.value)}
                          style={{ borderColor: formErrors.bairro && '#c00' }}
                        />
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label'>
                          {t('MotherName')}
                        </label>
                        <input
                          type='text'
                          className='form_control'
                          value={nomeMae}
                          onChange={(e) => setNomeMae(e.target.value)}
                          style={{ borderColor: formErrors.nomeMae && '#c00' }}
                        />
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='document' className='card_details_label'>
                            {t('MotherCPF')}
                          </label>
                          <CpfCnpjMask
                            value={cpfMae}
                            onChange={setCPFMae}
                            mode='CPF'
                            onBlur={() => handleValidate('cpfMae')}
                            style={{ borderColor: formErrors.cpfMae && '#c00' }}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='tel' className='card_details_label'>
                            {t('MotherCellPhone')}
                          </label>
                          <PhoneMask
                            value={phoneMae}
                            onChange={setPhoneMae}
                            onBlur={() => {
                              if (phoneMae !== '') {
                                if (!validatePhone(phoneMae, i18n.language)) {
                                  setErrorMessage('StudentPage.SystemMessage.InvalidFields');
                                } else {
                                  clearMessage();
                                }
                              }
                            }}
                            style={{ borderColor: formErrors.phoneMae && '#c00' }}
                          />
                        </div>
                      </div>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label'>
                          {t('FatherName')}
                        </label>
                        <input
                          type='text'
                          className='form_control'
                          value={nomePai}
                          onChange={(e) => setNomePai(e.target.value)}
                          style={{ borderColor: formErrors.nomePai && '#c00' }}
                        />
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='document' className='card_details_label'>
                            {t('FatherCPF')}
                          </label>
                          <CpfCnpjMask
                            value={cpfPai}
                            onChange={setCPFPai}
                            mode='CPF'
                            style={{ borderColor: formErrors.cpfPai && '#c00' }}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='tel' className='card_details_label'>
                            {t('FatherCellPhone')}
                          </label>
                          <PhoneMask
                            value={phonePai}
                            onChange={setPhonePai}
                            onBlur={() => {
                              if (phonePai !== '') {
                                if (!validatePhone(phonePai, i18n.language)) {
                                  setErrorMessage('StudentPage.SystemMessage.InvalidFields');
                                } else {
                                  clearMessage();
                                }
                              }
                            }}
                            style={{ borderColor: formErrors.phonePai && '#c00' }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label flex-row required'>
                          {t('FinancialResponsibleName')}
                        </label>
                        <input
                          type='text'
                          className='form_control'
                          value={nomeRespFin}
                          required={faturamento === 'S'}
                          onChange={(e) => setNomeRespFin(e.target.value)}
                          style={{ borderColor: formErrors.nomeRespFin && '#c00' }}
                        />
                      </div>
                      <div className='form_group'>
                        <label htmlFor='descript' className='card_details_label flex-row required'>
                          {t('FinancialResponsibleEmail')}
                          {sendEmailResp && <Loading type='1' style={{ marginLeft: '2%' }} />}
                        </label>
                        <input
                          required={faturamento === 'S'}
                          className='form_control'
                          value={emailRespFin}
                          onChange={(e) => setEmailRespFin(e.target.value)}
                          onBlur={(e) => (e.target.value !== '' ? verifyEmail(e.target.value, 'res') : {})}
                          type='email'
                          style={{ borderColor: formErrors.emailRespFin && '#c00' }}
                        />
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='document' className='card_details_label'>
                            {t('FinancialResponsibleRG')}
                          </label>
                          <input
                            type='text'
                            className='form_control'
                            value={rgRespFin}
                            onChange={(e) => setRGRespFin(e.target.value)}
                            style={{ borderColor: formErrors.rgRespFin && '#c00' }}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='document' className='card_details_label flex-row required'>
                            {t('FinancialResponsibleCPF')}
                          </label>
                          <CpfCnpjMask
                            required={faturamento === 'S'}
                            value={cpfRespFin}
                            onChange={setCPFRespFin}
                            mode='CPF'
                            style={{ borderColor: formErrors.cpfRespFin && '#c00' }}
                            onBlur={() => handleValidate('cpfRespFin')}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='parentesco' className='card_details_label'>
                            {t('Kinship')}
                          </label>
                          <select
                            className='form_control select'
                            value={parentesco}
                            onChange={(e) => setParentesco(e.target.value)}
                            style={{ borderColor: formErrors.parentesco && '#c00' }}
                          >
                            <option value='' disabled hidden></option>
                            <option value='Avô(ó)'>{t('Grandparent')}</option>
                            <option value='Pai'>{t('Father')}</option>
                            <option value='Mãe'>{t('Mother')}</option>
                            <option value='Tio(a)'>{t('UncleAunt')}</option>
                            <option value='Primo(a)'>{t('Cousin')}</option>
                            <option value='Irmão(ã)'>{t('Sibling')}</option>
                            <option value='Nenhum'>{t('None')}</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='tel' className='card_details_label'>
                            {t('FinancialResponsibleCellPhone')}
                          </label>
                          <PhoneMask
                            //required={faturamento === 'S'}
                            value={phoneRespFin}
                            onChange={setPhoneRespFin}
                            style={{ borderColor: formErrors.phoneRespFin && '#c00' }}
                            onBlur={() => handleValidate('phoneRespFin')}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='form_group'>
                      <label htmlFor='descript' className='card_details_label'>
                        {t('Description')}
                      </label>
                      <textarea
                        className='form_control'
                        value={sobre}
                        id=''
                        cols='30'
                        rows='10'
                        onChange={(e) => setSobre(e.target.value)}
                        style={{ borderColor: formErrors.sobre && '#c00' }}
                      ></textarea>
                    </div>
                    <div className='form_group'>
                      <button
                        disabled={sendSave}
                        type='button'
                        className='btn_card'
                        onClick={async () => {
                          setDisplayDocuments({
                            display: 'flex',
                            opacity: 1,
                          });
                        }}
                        style={{
                          display: 'block',
                          backgroundColor: 'rgb(170, 170, 170)',
                          width: 'min-content',
                          height: 28,
                          fontSize: '0.75rem',
                        }}
                      >
                        {sendSave ? (
                          <Loading type='1' style={{ marginLeft: '2%' }} />
                        ) : (
                          <svg
                            viewBox='0 0 25 25'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            style={{ transform: 'scale(0.8)' }}
                          >
                            <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                          </svg>
                        )}
                        Anexar docs
                      </button>
                    </div>
                    {!sendSave && (
                      <button className='btn_card mx-auto'>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {id === null && t('StudentPage.Modal.ButtonAdd')}
                        {id !== null && t('StudentPage.Modal.ButtonEdit')}
                      </button>
                    )}
                    {sendSave && (
                      <button className='btn_card mx-auto' style={{ background: '#ccc', border: 'none' }} disabled>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {t('SystemMessage.Loading.Processing')}
                        <Loading type='1' style={{ marginLeft: '2%' }} />
                      </button>
                    )}
                  </form>
                </div>
              </div>
              <AddDocumentsModal
                athleteID={athleteID}
                closeAddDocumento={closeAddDocumento}
                defaultAthleteDocuments={athleteDocuments}
                show={displayDocuments}
              />
            </main>

            <Footer />
          </div>
        </div>
      </div>

      {/* Modal de inativação */}
      <ModalInactivateStudent ref={modalInactivateStudentRef} studentId={id} student={{ id: id }} />

      <ModalHealthForm
        readOnly={!school_id}
        ref={modalHealthFormRef}
        isLoading={isSendingAnamnesisForm}
        student={{ athleteId: athleteID, name, birth }}
        onSubmit={onSubmitAnamnesisForm}
      />

      {/* WHATSAPP */}
      <WhatsAppComponent
        ref={whatsAppComponentRef}
        sendMessageWhatsApp={() => sendMessageWhatsApp({ name: nameLink, phone: phoneLink })}
        onClose={() => {
          setNameLink('');
          setPhoneLink('');
        }}
        sendButtonText={t('SendLink')}
      >
        <div className='form_group'>
          <label htmlFor='name' className='card_details_label'>
            {t('Name')}
          </label>
          <input
            type='text'
            required
            className='form_control'
            value={nameLink}
            onChange={(e) => setNameLink(e.target.value)}
          />
        </div>
        <div className='form_group'>
          <label htmlFor='phone' className='card_details_label'>
            {t('CellPhone')}
          </label>
          <PhoneMask required value={phoneLink} onChange={setPhoneLink} />
        </div>
      </WhatsAppComponent>

      <ModalCTsManager
        ref={modalCTsManagerRef}
        isLoading={isLoadingModalCTsManager}
        trainingCenters={trainingCenters}
        onSubmit={onSubmitTrainingCenter}
        onDelete={onDeleteTrainingCenter}
      />

      <ModalBenefits
        ref={modalBenefitsRef}
        isLoading={isLoadingModalCTsManager}
        benefits={benefits}
        onSubmit={onSubmitBenefit}
        onDelete={onDeleteBenefit}
      />
    </main>
  );
};

export default Student;
