import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { RiArrowDownSLine, RiArrowRightSLine, RiMenu2Fill } from 'react-icons/ri';
import Badge from 'react-bootstrap/Badge';
import { useSelector } from 'react-redux';
import './styles.css';
import { UserContext } from '../../Context/UserContext';
import MenuMobile from './menuMobile';
import ProfileMenuDropdown from './profileMenuDropdown';
import { dinamicImport } from './icons';
import UploadSignature from '../school/UploadSignature';
import ModalConfigStudentCertificate from '../../views/ModalConfigStudentCertificate/ModalConfigStudentCertificate';
import ModalApiSettings from '../../views/ModalApiSettings';
import { useTranslation } from 'react-i18next';
import HeaderMenu from '../menu/HeaderMenu/HeaderMenu';
import { FaGlobeAmericas } from 'react-icons/fa';
import i18next, { languages } from '../../i18nextConf';

const Sidebar = ({ children }) => {
  const { Cart } = useSelector((state) => state);
  const { i18n, t } = useTranslation();
  const [openSubmenuIndex, setOpenSubmenuIndex] = useState(-1);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const { clearContextData, logout, user, school, menu } = useContext(UserContext);
  const [currentRoute, setCurrentRoute] = useState(window.location.pathname);
  const [selectedMenuItem, setSelectedMenuItem] = useState('Home');
  const [selectedUserName, setSelectedUserName] = useState('');
  const [selectedInitialName, setSelectedInitialName] = useState('');
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isMenuProfile, setIsMenuProfile] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false); // Iniciado como expandido
  const [modalRef] = useState(useRef());
  const [modalRefClub] = useState(useRef());
  const [modalCertificatesRef] = useState(useRef());

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSidebarCollapsed(true);
      } else {
        setSidebarCollapsed(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (user) {
      const parts = user.name.split(' ');
      if (parts.length >= 1) {
        const firstName = parts[0];
        const lastName = parts[parts.length - 1];
        setSelectedUserName(`${firstName} ${lastName}`);

        const firstInitial = firstName.charAt(0);
        const lastInitial = lastName.charAt(0);
        setSelectedInitialName(`${firstInitial}${lastInitial}`);
      } else {
        setSelectedUserName(parts[0]);
      }
    }
  }, [user]);

  const handleChangeSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const handleExpandSidebar = () => {
    if (sidebarCollapsed) {
      setSidebarCollapsed(false);
    }
  };

  const toggleSubMenu = (index, e) => {
    e.target
      .closest('ul')
      .querySelectorAll('.menu-item')
      .forEach((item, i) => {
        item.classList.remove('active');
      });

    setSubMenuOpen(openSubmenuIndex === index);
    setOpenSubmenuIndex(openSubmenuIndex === index ? -1 : index);
  };

  const handleMenuItemClick = (item) => {
    setSelectedMenuItem(item.title);
  };

  const toggleMenuMobile = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const toggleMenuProfile = () => {
    setIsMenuProfile(!isMenuProfile);
  };

  const renderMenuItem = (item, index) => {
    const subMenuOpen = openSubmenuIndex === index;
    const icon = subMenuOpen ? <RiArrowDownSLine /> : <RiArrowRightSLine />;
    const hasChildren = item.children && item.children.length > 0;

    return (
      <li key={index} className={subMenuOpen ? 'opened' : 'closed'}>
        {hasChildren ? (
          <span
            className={`menu-item ${index === openSubmenuIndex ? 'active' : ''}`}
            onClick={(e) => toggleSubMenu(index, e)}
          >
            <div className='menu-item-container'>
              <span className='menu-rol-icon'>{dinamicImport(item.icon)}</span>
              <span className='menu-label'>{t('SystemMenu.' + item.title)}</span>
            </div>
            <div className='menu-icon'>{icon}</div>
          </span>
        ) : (
          <Link
            className={currentRoute === '/' + item.route ? 'menu-item active' : 'menu-item'}
            to={item.route === 'api-token' ? '#' : item.route}
            onClick={() => {
              setCurrentRoute('/' + item.route);
              handleMenuItemClick(item);
              if (item.route === 'api-token') {
                modalRefClub.current.showModal();
              }
            }}
          >
            <div className='menu-item-container'>
              <span className='menu-rol-icon'>{dinamicImport(item.icon)}</span>
              <span className='menu-label'>{t('SystemMenu.' + item.title)}</span>
            </div>
          </Link>
        )}

        {hasChildren && (
          <ul className={subMenuOpen && !sidebarCollapsed ? 'submenu-open' : 'submenu-closed'}>
            {item.children.map((itemChild, indexChild) => (
              <li key={`${indexChild}_${item.route}`}>
                {itemChild.route === 'upload-signature-school' && (
                  <span
                    className={`submenu-item`}
                    onClick={() => {
                      modalRef.current.showModal();
                      toggleMenuMobile();
                    }}
                  >
                    <span className='submenu-icon'>{dinamicImport('FaCircle')}</span>
                    <span className='submenu-label'>{t('SystemMenu.' + itemChild.title)}</span>
                  </span>
                )}
                {itemChild.route === 'student-certificate' && (
                  <span
                    className={`submenu-item`}
                    onClick={() => {
                      modalCertificatesRef.current.showModal();
                      toggleMenuMobile();
                    }}
                  >
                    <span className='submenu-icon'>{dinamicImport('FaCircle')}</span>
                    <span className='submenu-label'>{t('SystemMenu.' + itemChild.title)}</span>
                  </span>
                )}
                {itemChild.route !== 'upload-signature-school' && itemChild.route !== 'student-certificate' && (
                  <Link
                    to={`/${itemChild.route}`}
                    className={`submenu-item`}
                    onClick={() => handleMenuItemClick(itemChild)}
                  >
                    <span className='submenu-icon'>{dinamicImport('FaCircle')}</span>
                    <span className='submenu-label'>{t('SystemMenu.' + itemChild.title)}</span>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  };

  return (
    <div className='menuPage' onClick={handleExpandSidebar}>
      <ModalConfigStudentCertificate ref={modalCertificatesRef} />
      <UploadSignature ref={modalRef} />
      <ModalApiSettings ref={modalRefClub} />
      <div className={`sidebar-menu ${sidebarCollapsed ? 'collapsed' : ''}`}>
        <div className={`menu-body ${sidebarCollapsed ? '' : 'sidebar-collapsed'}`}>
          <ul>
            <li className='pt-3 pb-2'>
              <div className='menu-item-container'>
                <button className='menu-toggle-button' onClick={handleChangeSidebar}>
                  <RiMenu2Fill />
                </button>
                <span className={`menu-rol-icon m-0 ${sidebarCollapsed ? 'd-show' : 'd-none'}`}>
                  <img height={34} src='../../../img/icone.png' alt='' />
                </span>
                <span className='menu-label'>
                  <img height={34} src='../../../img/clubes/fensor.svg' alt='' />
                </span>
              </div>
            </li>
            {menu.map((item, index) => renderMenuItem(item, index))}
          </ul>
        </div>
      </div>
      <div className='page-content'>
        <div className='hidden-menu'>
          <div className='hidden-menu-left'>
            <button className='hidden-menu-button' onClick={toggleMenuMobile}>
              <RiMenu2Fill />
            </button>
          </div>
          <div className='hidden-menu-right'>
            <Link to={`/${menu[0].route}`}>
              <img src='../../../img/clubes/fensor.svg' className='hidden-icon' alt='Fensor' />
            </Link>
          </div>
        </div>
        {isMenuVisible && (
          <MenuMobile
            menu={menu}
            toggleMenuMobile={toggleMenuMobile}
            modalRef={modalRef}
            modalCertificatesRef={modalCertificatesRef}
            modalRefClub={modalRefClub}
          />
        )}
        <div className='header-topbar'>
          <div className='header-section-left'>{selectedMenuItem}</div>
          <div className='header-section-right'>
            <div className='header-section-fixed'>
              <div className='header-section-right-top d-none d-sm-block'>
                <div className='header-school'>{school ? school.schName : 'Clube'}</div>
                <div className='header-student'>
                  <span>Bem-vindo &nbsp;</span> {selectedUserName}
                </div>
              </div>
              <HeaderMenu
                title={t('Language')}
                icon={<FaGlobeAmericas />}
                options={[
                  ...Object.keys(languages).map((language) => ({
                    type: 'SPAN',
                    title: languages[language].nativeName,
                    onClick: () => i18next.changeLanguage(language),
                  })),
                ]}
              />
              {(user.role_id === 2 || user.role_id === 6) && (
                <div className='header-icon mr-2 text-center' style={{ position: 'relative', marginLeft: 10 }}>
                  <Link to={{ pathname: user.role_id === 6 ? '/school-my-cart' : '/my-cart' }}>
                    <Badge pill variant='danger' style={{ position: 'absolute', top: -5, right: 32, fontSize: 12 }}>
                      {Cart.length}
                    </Badge>
                    <img src='/img/icones/carrinhoBranco.png' style={{ width: 20, height: 20 }} alt='Cart' />
                  </Link>
                </div>
              )}
              <div onClick={toggleMenuProfile} className='header-icon'>
                {selectedInitialName}
              </div>
            </div>
            {isMenuProfile && <ProfileMenuDropdown logout={logout} clearContextData={clearContextData} />}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Sidebar;
