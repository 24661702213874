import React, { useEffect, useState, useRef } from 'react';
import Header from '../../components/school/Header';
import Footer from '../../components/school/Footer';
import Loading from '../../components/Loading';
import Message from '../../components/Message';
import Utils, { correctImageOrientation, getUserPhoto } from '../../../src/utils/utils';
import { Link } from 'react-router-dom';
import {
  getEmployeesSchool,
  saveEmployeeSchool,
  updateEmployeeSchool,
  uploadEmployeePhotoSchool,
} from '../../services/EmployeeService';
import {
  getStates,
  getCitysByStateSigla,
  verifyEmail as verifyUserEmail,
  searchByZipCodeCountry,
  getPositions,
  verifyCpfSchool,
  verifyLogin,
} from '../../services/SharedService';
import { useTranslation } from 'react-i18next';
import CpfCnpjMask from '../../components/inputs/CpfCnpjMask';
import PhoneMask from '../../components/inputs/PhoneMask';
import CEPMask from '../../components/inputs/CEPMask';
import MoneyMask from '../../components/inputs/MoneyMask';
import { validateCPF, validatePhone } from '../../utils/validators';

const MAX_VAL = 999_999_999_9;
const withValueNumberLimit = ({ floatValue }) => floatValue <= MAX_VAL;

const Employee = () => {
  const { t, i18n } = useTranslation();

  const [name, setName] = useState('');
  const [login, setLogin] = useState('');
  const [birth, setBirth] = useState('');
  const [phone, setPhone] = useState('');
  const [cpf, setCPF] = useState('');
  const [email, setEmail] = useState('');
  const [cep, setCEP] = useState('');
  const [endereco, setEndereco] = useState('');
  const [bairro, setBairro] = useState('');
  const [estado, setEstado] = useState('');
  const [dataInicio, setDataInicio] = useState('');
  const [role, setRole] = useState('');
  const [sobre, setSobre] = useState('');
  const [states, setStates] = useState([]);
  const [citys, setCitys] = useState([]);
  const [city_id, setCityID] = useState('');
  const [id, setID] = useState(null);
  const [school_id, setSchoolID] = useState('');
  const [companie_id, setCompanieID] = useState('');
  const [foto, setFoto] = useState('');
  const [status, setStatus] = useState('');
  const [sports, setSports] = useState([]);
  const [filterSport, setFilterSport] = useState([]);
  const [remuneration, setRemuneration] = useState();
  const [urlParams, setUrlParams] = useState(new URLSearchParams(window.location.search));

  // carregamento
  const [filterName, setFilterName] = useState('');
  const [filterFunction, setFilterFunction] = useState('');
  const [filterStatus, setFilterStatus] = useState('A');
  const [employees, setEmployees] = useState([]);
  const [employeesFilter, setEmployeesFilter] = useState([]);
  const [dirUsuFotoPrincipal, setDirUsuFotoPrincipal] = useState('');
  const [display, setDisplay] = useState({});

  // process
  const [process, setProcess] = useState(false);
  const [sendCity, setSendingCity] = useState(false);
  const [sendCep, setSendingCep] = useState(false);
  const [sendSave, setSendSave] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [sendUpload, setSendingUpload] = useState(false);
  const [sendCPF, setSendCPF] = useState(false);

  const initFormErrors = {
    cpf: false,
    login: false,
    name: false,
    status: false,
    phone: false,
    email: false,
    dataInicio: false,
    role: false,
  }
  const [formErrors, setFormErrors] = useState(initFormErrors);

  // messages
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');

  // libraries
  const { scrollTop } = Utils();
  const { MessageSuccessMarginTop, MessageDangerMarginTop } = Message();
  let modalScroll = useRef();

  useEffect(() => {
    async function getSession() {
      const schoolSession = JSON.parse(await localStorage.getItem('school'));

      setSchoolID(schoolSession.id);
      setCompanieID(schoolSession.companie_id);
      getEmployees(schoolSession.id);
    }

    async function getEmployees(school) {
      setProcess(true);

      const res = await getEmployeesSchool(school);

      setDirUsuFotoPrincipal(res.dir_usuFotoPrincipal);
      setEmployees(res.employees);

      const resp = res.employees.filter((item) => item.usuStatus === 'A');

      setEmployeesFilter(resp);
      setProcess(false);
    }

    getSession();
    fetchPositions();

    window.addEventListener('keydown', closeModalOnEscape);

    return () => {
      window.removeEventListener('keydown', closeModalOnEscape);
    };
  }, []);

  useEffect(() => {
    fetchStates();
  }, [i18n.language]);

  const fetchPositions = async () => {
    const res = await getPositions();
    setSports(res.sports);
  };

  const closeModalOnEscape = (event) => {
    if (event.key === 'Escape') closeAdd();
  };

  const fetchStates = async () => {
    const res = await getStates(i18n.language);
    setStates(res.states);
  };

  const getCitys = async (estado, localidade = '') => {
    setCitys([]);
    setEstado(estado !== null ? estado : '');
    setSendingCity(true);
    const res = await getCitysByStateSigla(estado, i18n.language);
    setSendingCity(false);

    for (let i = 0; i < res.cidades.length; i++) {
      let citCity = res.cidades[i].citCity;
      if (citCity === localidade) {
        setCityID(res.cidades[i].id);
      }
    }

    setCitys(res.cidades);
  };

  const buscaCEP = async () => {
    if (cep.length) {
      setSendingCep(true);
      const { success, streetName, neighborhood, states, state, cities, city } = await searchByZipCodeCountry(
        cep,
        i18n.language
      );
      setSendingCep(false);

      if (!success) {
        setEndereco('');
        setBairro('');
        setEstado('');
        setCityID('');
      } else {
        setEndereco(streetName);
        setBairro(neighborhood);
        setStates(states);
        setEstado(state);

        setCitys(cities);
        setCityID(city.id);
      }
    }
  };

  const search = (e) => {
    e.preventDefault();

    let registers = [];
    if (filterName !== '') {
      registers = employees.filter((item) => {
        return item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1;
      });
    } else {
      registers = employees;
    }

    if (filterFunction !== '') {
      registers = registers.filter((item) => {
        return item.role_id === filterFunction;
      });
    }

    if (filterStatus !== '') {
      registers = registers.filter((item) => item.usuStatus === filterStatus);
    }

    setEmployeesFilter(registers);
  };

  useEffect(() => {
    const add = urlParams.get('add');
    if (add === 'true') {
      openAdd();
    }
  }, [urlParams]);
  const openAdd = () => {
    clearMessage();
    setName('');
    setLogin('');
    setCPF('');
    setBirth('');
    setPhone('');
    setEmail('');
    setCEP('');
    setEndereco('');
    setBairro('');
    setEstado('');
    setDataInicio('');
    setRole('');
    setSobre('');
    setCitys([]);
    setCityID('');
    setID(null);
    setFoto('');
    setStatus('');
    setFilterSport([]);
    setRemuneration('');

    setDisplay({
      display: 'flex',
      opacity: 1,
    });
  };

  const closeAdd = () => {
    setDisplay({});
    setFormErrors(initFormErrors);
  };

  const verifyEmail = async (email) => {
    setSendEmail(true);
    const res = await verifyUserEmail({ email, id });
    setSendEmail(false);

    if (res.error !== false) {
      setEmail('');
      setErrorMessage('SystemMessage.Fail.' + res.messageKey);
    } else {
      clearMessage();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearMessage();
    if (!name) {
      console.log(name.length)
      handleValidate('name')
      return
    };
    if (!status) {
      handleValidate('status')
      return
    };
    if (!phone) {
      handleValidate('phone')
      return
    };
    if (!email) {
      handleValidate('email')
      return
    };
    if (!dataInicio) {
      handleValidate('dataInicio')
      return
    };
    if (!role) {
      handleValidate('role')
      return
    };
    if (cpf.length < 11 || cpf.length > 11) {
      setErrorMessage('EmployeePage.FormErrors.Cpf');
      setFormErrors({ ...formErrors, cpf: true });
      return;
    }

    setSendSave(true);
    const res =
      id === null
        ? await saveEmployeeSchool({
          companieId: companie_id,
          schoolId: school_id,
          name: name,
          login: login,
          cpf: cpf,
          birthdate: birth,
          phone: phone,
          email: email,
          cep: cep,
          endereco: endereco,
          cityId: city_id,
          bairro: bairro,
          dataInicio: dataInicio,
          roleId: role,
          sobre: sobre,
          password: email,
          status: status,
          sports: filterSport,
          remuneration: remuneration,
        })
        : await updateEmployeeSchool({
          userId: id,
          schoolId: school_id,
          name: name,
          login: login,
          cpf: cpf,
          birthdate: birth,
          phone: phone,
          email: email,
          cep: cep,
          endereco: endereco,
          cityId: city_id,
          bairro: bairro,
          dataInicio: dataInicio,
          roleId: role,
          sobre: sobre,
          status: status,
          sports: filterSport,
          remuneration: remuneration,
        });
    setSendSave(false);

    if (res.error || formErrors.cpf) {
      const errorTypes = {
        usuCPF: "CPFAlreadyExists",
        email: "EmailAlreadyExists",
        usuCPF_email: "CPFAndEmailAlreadyExists",
        email_usuCPF: "CPFAndEmailAlreadyExists"
      }
      const errorsKeys = res.error ? Object.keys(res.error.response.data.errors).join('_') : ''
      const errorTypeKeys = Object.keys(errorTypes)
      const translateError = errorTypeKeys.includes(errorsKeys) ? errorTypes[errorsKeys] : 'InternalError'
      setErrorMessage('SystemMessage.Fail.' + translateError);
    } else {
      setSuccessMessage('SystemMessage.Success.' + res.create.messageKey);
      setID(res.create.id);
      setEmployees(res.employees);

      let resp = res.employees;
      if (filterStatus !== '') resp = resp.filter((item) => item.usuStatus === filterStatus);

      setEmployeesFilter(resp);
    }

    scrollTop(modalScroll);
  };

  const handleFoto = async (e) => {
    const foto = await correctImageOrientation(e.target.files[0]);
    clearMessage();

    setSendingUpload(true);
    const res = await uploadEmployeePhotoSchool({ schoolId: school_id, userId: id, photo: foto });
    setSendingUpload(false);

    if (res.success === false) {
      setErrorMessage('SystemMessage.File.Fail.' + res.messageKey);
    } else {
      setSuccessMessage('SystemMessage.File.Success.' + res.messageKey);
      setFoto(res.arq);
      setEmployees(res.employees);
      setEmployeesFilter(res.employees);
    }
  };

  const edit = (e, user) => {
    clearMessage();
    setID(user.id);
    setName(user.name);
    setLogin(user.usuLogin);
    setCPF(user.usuCPF);
    setBirth(user.usuBirth !== null ? user.usuBirth : '');
    setPhone(user.usuPhone);
    setEmail(user.email);
    setCEP(user.usuCEP !== null ? user.usuCEP : '');
    setEndereco(user.usuEndereco !== null ? user.usuEndereco : '');
    setBairro(user.usuBairro !== null ? user.usuBairro : '');
    setEstado(user.staStateSigla !== null ? user.staStateSigla : '');
    setDataInicio(user.usuDataInicioSchool);
    setRole(user.role_id);
    setSobre(user.usuSobre !== null ? user.usuSobre : '');
    getCitys(user.staStateSigla, user.citCity);
    setCityID(user.city_id !== null ? user.city_id : '');
    setSchoolID(user.school_id);
    setCompanieID(user.companie_id);
    setStatus(user.usuStatus);
    setFilterSport(user.sports);
    setRemuneration(user.remuneration);

    if (user.usuFotoPrincipal !== null) setFoto(dirUsuFotoPrincipal + '/' + user.usuFotoPrincipal);
    else setFoto('');

    setDisplay({
      display: 'flex',
      opacity: 1,
    });
  };

  const setErrorFoto = () => {
    setErrorMessage('EmployeePage.Modal.SystemMessage.ErrorPhotoUserNotSaved');
  };

  const handlerValidateLogin = async () => {
    if (login.length > 0) {
      const res = await verifyLogin({ login: login, id: id });
      if (res.error === true) {
        setErrorMessage('StudentPage.SystemMessage.InvalidFields');
        setFormErrors({ ...formErrors, login: true });
      } else {
        clearMessage();
        setFormErrors({ ...formErrors, login: false });
      }
    } else {
      setFormErrors({ ...formErrors, login: true });
      setErrorMessage('StudentPage.SystemMessage.InvalidFields');
    }
  };

  const setErrorMessage = (message, element) => {
    setMessage(message);
    setError(true);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      return
    }
    scrollTop(modalScroll);
  };

  const setSuccessMessage = (message) => {
    setMessage(message);
    setSuccess(true);
    scrollTop(modalScroll);
  };

  const clearMessage = () => {
    setSuccess(false);
    setError(false);
    setMessage('');
  };

  const addSports = (event) => {
    clearMessage();
    var index = event.nativeEvent.target.selectedIndex;
    let newSportsFilter = filterSport.filter((e) => e.id === event.target.value);

    if (newSportsFilter.length > 0) {
      setErrorMessage('ClassPage.SystemMessage.StudentAlreadyIncluded');
    } else {
      setFilterSport([
        ...filterSport,
        {
          id: event.target.value,
          label: event.nativeEvent.target[index].text,
        },
      ]);
    }
  };

  const delSport = (item) => {
    clearMessage();
    setFilterSport(filterSport.filter((e) => e.id !== item.id));
  };

  const verifyCPF = async (cpf) => {
    if (i18n.language === 'pt-BR' && id == null && cpf.length == 11) {
      setSendCPF(true);
      const res = await verifyCpfSchool({ cpf: cpf, schoolId: school_id });
      setSendCPF(false);

      if (res.error === true) {
        setCPF('');
        setErrorMessage('StudentPage.SystemMessage.InvalidFields');
        setFormErrors({ ...formErrors, cpf: true });
      } else {
        clearMessage();
        setCPF(cpf);
        setFormErrors({ ...formErrors, cpf: false });
      }
    }
  };

  const handleValidate = async (field) => {
    switch (field) {
      case 'name':
        if (name.length > 90) {
          setErrorMessage('StudentPage.SystemMessage.InvalidFields');
          setFormErrors({ ...formErrors, name: true });
        } else if (name.length <= 1) {
          setErrorMessage('EmployeePage.FormErrors.Name');
          setFormErrors({ ...formErrors, name: true });
        } else {
          clearMessage();
          setFormErrors({ ...formErrors, name: false });
        }
        break;
      case 'login':
        handlerValidateLogin();
        break;
      case 'email':
        if (!email) {
          setErrorMessage('EmployeePage.FormErrors.Email');
          setFormErrors({ ...formErrors, email: true });
        } else {
          clearMessage();
          setFormErrors({ ...formErrors, email: false });
        }
        break;
      case 'phone':
        if (!phone) {
          setErrorMessage('EmployeePage.FormErrors.Phone');
          setFormErrors({ ...formErrors, phone: true });
        } else {
          const isValidPhone = validatePhone(phone, i18n.language);

          if (isValidPhone) {
            clearMessage();
            setFormErrors({ ...formErrors, phone: false });
          } else {
            setErrorMessage('EmployeePage.FormErrors.ValidPhone');
            setFormErrors({ ...formErrors, phone: true });
          }
        }
        break;
      case 'cpf':
        if (validateCPF(cpf, i18n.language)) {
          if (await verifyCPF(cpf, i18n.language)) {
            clearMessage();
            setFormErrors({ ...formErrors, cpf: false });
          }
        } else {
          setErrorMessage('EmployeePage.FormErrors.Cpf');
          setFormErrors({ ...formErrors, cpf: true });
        }
        break;
      case 'status':
        if (!status.length) {
          setErrorMessage('EmployeePage.FormErrors.Status');
          setFormErrors({ ...formErrors, status: true });
        } else {
          clearMessage();
          setFormErrors({ ...formErrors, status: false });
        }
        break;
      case 'dataInicio':
        if (!dataInicio) {
          const dateField = document.querySelector('input[name="date"].form_control');
          setErrorMessage('EmployeePage.FormErrors.DataInicio', dateField);
          setFormErrors({ ...formErrors, dataInicio: true });
        } else {
          clearMessage();
          setFormErrors({ ...formErrors, dataInicio: false });
        }
        break;
      case 'role':
        if (!role) {
          const roleField = document.querySelector('select#role.form_control');
          setErrorMessage('EmployeePage.FormErrors.Role', roleField);
          setFormErrors({ ...formErrors, role: true });
        } else {
          clearMessage();
          setFormErrors({ ...formErrors, role: false });
        }
        break;
      default:
        break;
    }
  };

  return (
    <main>
      <div className='container-fluid container_painel'>
        <div className='container'>
          <div className='row content_painel'>
            <main className='content_panel'>
              <div className='sidebar'>
                <div className='card' style={{ flexDirection: 'column' }}>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('EmployeePage.AddEmployeeCard.Title')}</h2>
                    <p className='card_subtitle'>{t('EmployeePage.AddEmployeeCard.Subtitle')}</p>
                  </div>
                  <button
                    onClick={openAdd}
                    className='btn_card mx-auto mt-4 btn_open_modal'
                    data-modal='#add_funcionario'
                  >
                    <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                    </svg>
                    {t('EmployeePage.AddEmployeeCard.Button')}
                  </button>
                </div>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('EmployeePage.FilterEmployeeCard.Title')}</h2>
                    <p className='card_subtitle'>{t('EmployeePage.FilterEmployeeCard.Subtitle')}</p>
                  </div>
                  <form action='' method='post' className='form_card'>
                    <div className='form_group'>
                      <label htmlFor='name' className='card_details_label'>
                        {t('Name')}
                      </label>
                      <input type='text' className='form_control' onChange={(e) => setFilterName(e.target.value)} />
                    </div>
                    <div className='form_group'>
                      <label htmlFor='title' value={status} className='card_details_label'>
                        {t('Status')}
                      </label>
                      <select
                        required
                        value={filterStatus}
                        className='form_control select'
                        onChange={(e) => setFilterStatus(e.target.value)}
                        style={{ borderColor: formErrors.status && '#c00' }}
                        onBlur={() => handleValidate('status')}
                      >
                        <option value=''>{t('All')}</option>
                        <option value='A'>{t('StatusType.Active')}</option>
                        <option value='I'>{t('StatusType.Inactive')}</option>
                      </select>
                    </div>
                    <div className='form_group'>
                      <label htmlFor='position' className='card_details_label'>
                        {t('Occupation')} *
                      </label>
                      <select
                        className='form_control select'
                        value={filterFunction}
                        onChange={(e) => setFilterFunction(+e.target.value || e.target.value)}
                        style={{ borderColor: formErrors.filterFunction && '#c00' }}
                        onBlur={() => handleValidate('filterFunction')}
                      >
                        <option value=''>{t('All', { context: 'female' })}</option>
                        <option value={6}>{t('EmployeeModel.Occupations.Manager')}</option>
                        <option value={7}>{t('EmployeeModel.Occupations.Teacher')}</option>
                        <option value={8}>{t('EmployeeModel.Occupations.Secretary')}</option>
                        <option value={9}>{t('EmployeeModel.Occupations.Coordinator')}</option>
                        <option value={10}>{t('EmployeeModel.Occupations.Intern')}</option>
                        <option value={20}>{t('EmployeeModel.Occupations.Physiotherapist')}</option>
                        <option value={21}>{t('EmployeeModel.Occupations.MassageTherapist')}</option>
                        <option value={11}>{t('EmployeeModel.Occupations.Other')}</option>
                      </select>
                    </div>
                    <button className='btn_card mx-auto' onClick={search}>
                      <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                      </svg>
                      {t('Search')}
                    </button>
                  </form>
                </div>
              </div>
              <div className='content'>
                <div className='card'>
                  <div className='card_header'>
                    <h2 className='card_title'>{t('EmployeePage.PageTitle')}</h2>
                    <p className='card_subtitle'>{t('EmployeePage.PageSubtitle')}</p>
                  </div>
                  <div className='list_card'>
                    {process && (
                      <p className='loading-card'>
                        <Loading type='1' />
                      </p>
                    )}
                    {!process &&
                      employeesFilter.map((item, i) => {
                        return (
                          <div className='card_list' key={`employees_${i}`}>
                            <div className='img_list_user'>
                              <img src={getUserPhoto(dirUsuFotoPrincipal + '/', item.usuFotoPrincipal)} alt='' />
                            </div>
                            <div className='content_card'>
                              <div className='card_details'>
                                <p className='card_details_title' title={item.name}>
                                  {item.name.length > 20 ? item.name.substr(0, 20) + '...' : item.name}
                                </p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>{t('Occupation')}: </p>
                                <p className='card_details_text'>{t(`EmployeeModel.Occupations.${item.rolRole}`)}</p>
                              </div>
                              <div className='card_details'>
                                <p className='card_details_label'>{t('Status')}: </p>
                                <p className='card_details_text'>{t(`StatusType.${item.usuStatus}`)}</p>
                              </div>
                            </div>
                            <Link
                              style={{ marginBottom: 10, fontWeight: 500 }}
                              to={{
                                pathname: '/detail-employee',
                                state: {
                                  user_id: item.id,
                                },
                              }}
                              className='btn_card'
                            >
                              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                              </svg>
                              {t('SeeEmployee')}
                            </Link>
                            <span
                              style={{ backgroundColor: 'orange', fontWeight: 500, border: '1px solid orange' }}
                              onClick={(e) => edit(e, item)}
                              className='btn_card'
                            >
                              <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                              </svg>
                              {t('EditEmployee')}
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className='modal' id='add_funcionario' style={display}>
                <div className='modal_content' ref={modalScroll}>
                  <div className='modal_close' onClick={closeAdd}>
                    X
                  </div>
                  <h3 className='modal_title'>
                    {id === null && t('EmployeePage.Modal.TitleAdd')}
                    {id !== null && t('EmployeePage.Modal.TitleEdit')}
                  </h3>
                  {error && (
                    <MessageDangerMarginTop title={t('Attention_exclamation') + ' '} description={t(message)} />
                  )}
                  {success && (
                    <MessageSuccessMarginTop title={t('Success_exclamation') + ' '} description={t(message)} />
                  )}
                  <form action='' onSubmit={handleSubmit} method='post' className='form_modal'>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label'>
                          {t('Name')} *
                        </label>
                        <input
                          type='text'
                          className='form_control'
                          value={name}
                          style={{ borderColor: formErrors.name && '#c00' }}
                          onBlur={() => handleValidate('name')}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className='form_group'>
                        <label htmlFor='login' className='card_details_label flex-row required'>
                          {t('Login')}
                        </label>
                        <input
                          type='text'
                          required
                          className='form_control'
                          value={login}
                          onChange={(e) => setLogin(e.target.value)}
                          style={{ borderColor: formErrors.login && '#c00' }}
                          onBlur={() => handleValidate('login')}
                        />
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='title' className='card_details_label'>
                            {t('Status')} *
                          </label>
                          <select
                            value={status}
                            className='form_control select'
                            style={{ borderColor: formErrors.status && '#c00' }}
                            onBlur={() => handleValidate('status')}
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <option value='' disabled></option>
                            <option value='A'>{t('StatusType.Active')}</option>
                            <option value='I'>{t('StatusType.Inactive')}</option>
                          </select>
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='data' className='card_details_label'>
                            {t('BirthDate')}
                          </label>
                          <input
                            type='date'
                            className='form_control'
                            value={birth}
                            onChange={(e) => setBirth(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='name' className='card_details_label'>
                            {t('CPF')}
                          </label>
                          <CpfCnpjMask
                            value={cpf}
                            onChange={setCPF}
                            mode='CPF'
                            style={{ borderColor: formErrors.cpf && '#c00' }}
                          />
                        </div>
                      </div>
                      <div className='group_50'>
                        <div className='form_group'>
                          <label htmlFor='tel' className='card_details_label'>
                            {t('CellPhone')} *
                          </label>
                          <PhoneMask
                            value={phone}
                            onChange={setPhone}
                            style={{ borderColor: formErrors.phone && '#c00' }}
                            onBlur={() => handleValidate('phone')}
                          />
                        </div>
                      </div>
                      <div className='form_group'>
                        <label htmlFor='email' className='card_details_label flex-row'>
                          {t('Email')} *
                          {sendEmail && <Loading type='1' style={{ marginLeft: '2%' }} />}
                        </label>
                        <input
                          type='email'
                          className='form_control'
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          style={{ borderColor: formErrors.email && '#c00' }}
                          onBlur={() => handleValidate('email')}
                        />
                      </div>
                      <div className='form_group'>
                        <label htmlFor='remuneration' className='card_details_label flex-row'>
                          {t('Remuneration')}
                          {sendEmail && <Loading type='1' style={{ marginLeft: '2%' }} />}
                        </label>
                        <MoneyMask value={remuneration} onChange={setRemuneration} isAllowed={withValueNumberLimit} />
                        {/*  <input
                          type='number'
                          required
                          className='form_control'
                          value={remuneration}
                          onChange={(e) => setRemuneration(e.target.value)}
                        /> */}
                      </div>
                      <div className='form_group'>
                        <label htmlFor='day' className='card_details_label'>
                          {t('SportType')}
                        </label>
                        <select
                          name='sport'
                          className='form_control select'
                          value={filterSport}
                          onChange={(e) => addSports(e)}
                        >
                          <option value=''></option>
                          {sports
                            .filter((item) => !filterSport.some((s) => s.id == item.id))
                            .map((sport) => (
                              <option key={'tipo-esporte' + sport.id} value={sport.id}>
                                {t('Sports.Name.' + sport.label)}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className='list_select'>
                        {filterSport.map((item, i) => (
                          <div className='list_item' key={`stu_${i}`}>
                            <p style={{ fontSize: 13, marginRight: 5 }}>{item.label}</p>
                            <button type='button' className='remove' onClick={() => delSport(item)}>
                              <svg
                                width='10'
                                height='10'
                                viewBox='0 0 10 10'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M8.53555 1.46445C7.59121 0.520117 6.33555 0 5 0C3.66445 0 2.40879 0.520117 1.46445 1.46445C0.520117 2.40879 0 3.66445 0 5C0 6.33555 0.520117 7.59121 1.46445 8.53555C2.40879 9.47988 3.66445 10 5 10C6.33555 10 7.59121 9.47988 8.53555 8.53555C9.47988 7.59121 10 6.33555 10 5C10 3.66445 9.47988 2.40879 8.53555 1.46445ZM7.1627 6.47207C7.35332 6.66289 7.35332 6.97188 7.1627 7.1627C7.06738 7.25801 6.94238 7.30566 6.81738 7.30566C6.69238 7.30566 6.56738 7.25801 6.47207 7.1627L5 5.69062L3.52793 7.1627C3.43262 7.25801 3.30762 7.30566 3.18262 7.30566C3.05762 7.30566 2.93262 7.25801 2.8373 7.1627C2.64668 6.97188 2.64668 6.66289 2.8373 6.47207L4.30937 5L2.8373 3.52793C2.64668 3.33711 2.64668 3.02813 2.8373 2.8373C3.02813 2.64648 3.33711 2.64648 3.52793 2.8373L5 4.30937L6.47207 2.8373C6.66289 2.64668 6.97188 2.64648 7.1627 2.8373C7.35352 3.02793 7.35352 3.33711 7.1627 3.52793L5.69062 5L7.1627 6.47207Z'
                                  fill='#B2B1B1'
                                />
                              </svg>
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                    {sendUpload && (
                      <div
                        className='group_50'
                        style={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          background: '#218135',
                          borderRadius: '10px',
                          color: 'white',
                          flexDirection: 'column',
                        }}
                      >
                        <label htmlFor='file'>{t('SystemMessage.Loading.WaitSending')}</label>
                        <Loading type='3' style={{ color: 'white', marginTop: 5 }} />
                      </div>
                    )}
                    {foto !== '' && !sendUpload && (
                      <div className='group_50' style={{ justifyContent: 'space-around' }}>
                        <img style={{ borderRadius: 10, marginBottom: 10, width: '90%' }} src={foto} alt='' />
                        <label
                          style={{
                            cursor: 'pointer',
                            padding: '12px 22px',
                            borderRadius: '50px',
                            border: '1px solid rgb(2, 132, 44)',
                            color: 'rgb(2, 132, 44)',
                            fontSize: '13px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'center',
                          }}
                        >
                          <input type='file' name='file[]' onChange={(e) => handleFoto(e)} />
                          {t('ChangePhoto')}
                        </label>
                      </div>
                    )}
                    {id !== null && foto === '' && !sendUpload && (
                      <div className='group_50 upload_file'>
                        <label htmlFor='file'></label>
                        <input
                          type='file'
                          name='file[]'
                          id='file'
                          onChange={(e) => handleFoto(e)}
                          style={{
                            display: 'initial',
                            opacity: 0,
                            cursor: 'pointer',
                            width: '100%',
                            height: '100%',
                          }}
                        />
                      </div>
                    )}
                    {id === null && foto === '' && !sendUpload && (
                      <div className='group_50 upload_file' onClick={() => setErrorFoto()}>
                        <label htmlFor='file'></label>
                      </div>
                    )}
                    <div className='form_group'>
                      <label htmlFor='name' className='card_details_label flex-row'>
                        {t('CEP')}
                        {sendCep && <Loading type='1' style={{ marginLeft: '2%' }} />}
                      </label>
                      <CEPMask value={cep} onChange={setCEP} onBlur={buscaCEP} />
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label'>
                          {t('Address')}
                        </label>
                        <input
                          type='text'
                          value={endereco}
                          className='form_control'
                          onChange={(e) => setEndereco(e.target.value)}
                        />
                      </div>
                      <div className='form_group'>
                        <label htmlFor='name' className='card_details_label flex-row'>
                          {t('City')}
                          {sendCity && <Loading type='1' style={{ marginLeft: '2%' }} />}
                        </label>
                        <select
                          className='form_control select'
                          value={city_id}
                          onChange={(e) => setCityID(e.target.value)}
                        >
                          <option value=''>-- {t('Select')} --</option>
                          {citys.map((item) => (
                            <option value={item.id} key={item.citCity}>
                              {item.citCity}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='tel' className='card_details_label'>
                          {t('State')}
                        </label>
                        <select
                          className='form_control select'
                          value={estado}
                          onChange={(e) => getCitys(e.target.value, '')}
                        >
                          <option value=''>-- {t('Select')} --</option>
                          {states.map((item) => (
                            <option value={item.staStateSigla} key={item.staState}>
                              {item.staState}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className='form_group'>
                        <label htmlFor='tel' className='card_details_label'>
                          {t('Neighborhood')}
                        </label>
                        <input
                          type='text'
                          value={bairro}
                          className='form_control'
                          onChange={(e) => setBairro(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='date' className='card_details_label'>
                          {t('StartDate')} *
                        </label>
                        <input
                          type='date'
                          name='date'
                          value={dataInicio}
                          className='form_control'
                          onChange={(e) => setDataInicio(e.target.value)}
                          style={{ borderColor: formErrors.dataInicio && '#c00' }}
                          onBlur={() => handleValidate('dataInicio')}
                        />
                      </div>
                    </div>
                    <div className='group_50'>
                      <div className='form_group'>
                        <label htmlFor='position' className='card_details_label'>
                          {t('Occupation')} *
                        </label>
                        <select
                          className='form_control select'
                          value={role}
                          id='role'
                          onChange={(e) => setRole(e.target.value)}
                          style={{ borderColor: formErrors.role && '#c00' }}
                          onBlur={() => handleValidate('role')}
                        >
                          <option value=''>-- {t('Select')} --</option>
                          <option value='6'>{t('EmployeeModel.Occupations.Manager')}</option>
                          <option value='7'>{t('EmployeeModel.Occupations.Teacher')}</option>
                          <option value='8'>{t('EmployeeModel.Occupations.Secretary')}</option>
                          <option value='9'>{t('EmployeeModel.Occupations.Coordinator')}</option>
                          <option value='10'>{t('EmployeeModel.Occupations.Intern')}</option>
                          <option value='20'>{t('EmployeeModel.Occupations.Physiotherapist')}</option>
                          <option value='21'>{t('EmployeeModel.Occupations.MassageTherapist')}</option>
                          <option value='11'>{t('EmployeeModel.Occupations.Other')}</option>
                        </select>
                      </div>
                    </div>
                    <div className='form_group'>
                      <label htmlFor='descript' className='card_details_label'>
                        {t('Description')}
                      </label>
                      <textarea
                        className='form_control'
                        value={sobre}
                        id=''
                        cols='30'
                        rows='10'
                        onChange={(e) => setSobre(e.target.value)}
                      ></textarea>
                    </div>
                    {!sendSave && (
                      <button className='btn_card mx-auto'>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {id === null && t('EmployeePage.Modal.ButtonAdd')}
                        {id !== null && t('EmployeePage.Modal.ButtonEdit')}
                      </button>
                    )}
                    {sendSave && (
                      <button className='btn_card mx-auto' style={{ background: '#ccc', border: 'none' }} disabled>
                        <svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
                          <path d='M21.3389 3.66113C18.9781 1.29996 15.8387 0 12.5 0C9.1613 0 6.02229 1.29996 3.66113 3.66113C1.30036 6.02189 0 9.1613 0 12.5C0 15.8387 1.30036 18.9777 3.66113 21.3389C6.02189 23.6996 9.1613 25 12.5 25C15.8387 25 18.9781 23.6996 21.3389 21.3389C23.6996 18.9781 25 15.8391 25 12.5C25 9.1609 23.6996 6.02189 21.3389 3.66113ZM12.809 4.24441L16.4611 3.17808L20.1206 5.84667L20.2347 9.63724L16.6201 11.2277L12.809 8.45844V4.24441ZM8.09536 11.7865L9.53108 16.2057L7.16124 18.7149L3.48985 16.9741L2.50521 13.1812L4.52619 10.2162L8.09536 11.7865ZM4.75785 9.68578L4.87584 5.76538L8.52553 3.17413L12.2301 4.25586V8.43002L8.33531 11.2596L4.75785 9.68578ZM9.77655 16.7886H15.2795L15.3142 16.6825L17.5826 19.0847L15.7061 22.0784H9.33297L7.51681 19.1813L9.77655 16.7886ZM17.9828 18.6651L15.5179 16.0554L16.9248 11.7261L20.4426 10.1783L22.5315 13.2431L21.4841 17.0053L17.9828 18.6651ZM22.2197 6.01282L20.3471 5.29575L16.6655 2.61137L15.5325 1.20998C18.2997 1.95389 20.6644 3.69033 22.2197 6.01282ZM9.46754 1.20998L8.35504 2.58611L4.41135 5.38612L2.78225 6.00966C4.33755 3.68875 6.70147 1.95349 9.46754 1.20998ZM0.832702 11.7869L2.01861 13.6101L2.98983 17.3514L2.90459 19.1683C1.58529 17.276 0.810207 14.9768 0.810207 12.5C0.810207 12.2604 0.818495 12.0229 0.832702 11.7869ZM7.53023 23.0793L9.69565 22.6574H15.343L17.4808 23.0741C15.9689 23.7892 14.2802 24.1902 12.4996 24.1902C10.7237 24.1898 9.03935 23.7908 7.53023 23.0793ZM22.1321 19.1158L22.0406 17.1639L23.0702 13.4653L24.1673 11.779C24.1819 12.0177 24.1902 12.2581 24.1902 12.5C24.1898 14.9539 23.4293 17.2334 22.1321 19.1158Z' />
                        </svg>
                        {t('SystemMessage.Loading.Processing')}
                        <Loading type='1' style={{ marginLeft: '2%' }} />
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      </div>
    </main>
  );
};

export default Employee;
